import { createMuiTheme } from "@material-ui/core";
import baseTheme from './theme.json';
import Color from 'color';
import Settings from "../../settings.yml";

const primaryMain = Settings.colorPrimary;
let primaryColor = Color(primaryMain);
baseTheme.palette.primary = {
  main: primaryMain,
  light: primaryColor.lighten(0.3).rgb().string(),
  dark: primaryColor.darken(0.3).rgb().string(),
  contrastText: primaryColor.isDark() ? '#fff' : '#000'
};

const secondaryMain = Settings.colorSecondary;
let secondaryColor = Color(secondaryMain);
baseTheme.palette.secondary = {
  main: secondaryMain,
  light: secondaryColor.lighten(0.3).rgb().string(),
  dark: secondaryColor.darken(0.3).rgb().string(),
  contrastText: secondaryColor.isDark() ? '#fff' : '#000'
};

let tertiaryColor = Color(Settings.colorPrimary)
                    .mix(Color(Settings.colorSecondary))
                    .rotate(-180).lighten(0.3).desaturate(0.1).rgb();
const tertiaryMain = tertiaryColor.string();
baseTheme.palette.tertiary = {
  main: tertiaryMain,
  light: tertiaryColor.lighten(0.3).rgb().string(),
  dark: tertiaryColor.darken(0.3).rgb().string(),
  contrastText: tertiaryColor.isDark() ? '#fff' : '#000'
};

baseTheme.overrides.MuiTableRow.hover["&$hover"]["&:hover"]['backgroundColor'] = Color(primaryMain).fade(0.9).rgb().string();

export default createMuiTheme(baseTheme);