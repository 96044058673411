import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { theme, history } from '../config';
import { isDefined } from "../utils";
import Container from "@material-ui/core/Container";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0, 2, 0)
  },
  header: {
    padding: theme.spacing(3),
  },
  nonMainHeader: {
    color: theme.palette.primary.main,
  },
  mainHeader: {
    color: theme.palette.primary.contrastText,
  },
  mainContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px 4px 0px 0px',
    padding: theme.spacing(1),
  },
  arrowBack: {
    color: 'gray',
    '&:hover': {
      color: 'black',
      cursor: 'pointer'
    },
  }
}));

export const InfoContainer = props => {
  const {
    title, children, subtitle, titleAvatar, actionButton, mainContainer, className, goBackArrow, ...rest
  } = props;
  const classes = useStyles();

  return (
    <Container
      component={Paper}
      className={clsx(className, classes.paper)}
      {...rest}
    >
      <Grid
        container justify="space-evenly" direction="row" alignItems="center"
        className={clsx({
          [classes.mainContainer]: mainContainer
        })}
      >      
        <Grid container item xs={8} alignItems="center" justify="flex-start">
          {goBackArrow &&
            <Grid item xs={1}>
              <ArrowBackIcon onClick={() => {history.push(goBackArrow)}} className={classes.arrowBack}/>
            </Grid>
          }
          <Grid item xs={goBackArrow ? 11 : 12}>
            <CardHeader
              variant="h5"
              title={title}
              className={classes.header}
              titleTypographyProps={{
                className: clsx({
                  [classes.nonMainHeader]: !isDefined(mainContainer) || !mainContainer,
                  [classes.mainHeader]: isDefined(mainContainer) && mainContainer
                })
              }}
              subheader={subtitle}
              subheaderTypographyProps={{
                style: {
                  ...theme.typography.subtitle1,
                  color: mainContainer ? theme.palette.primary.contrastText : theme.typography.h3.color,
                }
              }}
            />
          </Grid>
        </Grid>
        {titleAvatar}
        <Grid item xs={3}>
          {actionButton}
        </Grid>
      </Grid>
      {children}
    </Container>
  );
};

