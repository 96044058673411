import React from "react";

export const DateAxisTick = props => {
  const {x, y, payload, tickFormatter} = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#333"
            transform="rotate(-35)">
        {tickFormatter(payload.value)}
      </text>
    </g>
  );
};