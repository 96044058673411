import {Grid} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {ContractCCH, ContractMaximeter} from "./";
import React from "react";
import {withStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import {InfoContainer} from "../../../components";
import {withTranslation} from "react-i18next";
import {compose} from "redux";


const styles = theme => ({});

function TabPanel(props) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`consume-tab-panel-${index}`}
      {...other}
    >
      {value === index && <Box p={3} style={{margin: 0, padding: 0}}>{children}</Box>}
    </Typography>
  );
}

class ConsumptionDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIdx: 0,
    };
  }

  handleTabChange = (event, selectedTabIdx) => {
    event.preventDefault();
    this.setState({
      selectedTabIdx: selectedTabIdx
    });
  };

  render() {
    const {contract, t} = this.props;
    const {selectedTabIdx} = this.state;
    return (
      <InfoContainer
        title={t('your', {count: 1, what: t('consumption').toLowerCase()})}
        subtitle={t('do_multiple', {what: t('download'), over: t('consumption_curve').toLowerCase(), over2: t('max_monthly_power').toLowerCase()})}
        style={{ minHeight: "90vh" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              value={selectedTabIdx}
              onChange={this.handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              centered
            >
              <Tab label={t('consumption_curve')} id={"consumption-tab-0"}/>
              <Tab label={t('max_monthly_power')} id={"consumption-tab-1"}/>
            </Tabs>
          </Grid>
        </Grid>
        <TabPanel value={selectedTabIdx} index={0}>
          <ContractCCH contract={contract}/>
        </TabPanel>
        <TabPanel value={selectedTabIdx} index={1}>
          <ContractMaximeter contract={contract}/>
        </TabPanel>
      </InfoContainer>
    )
  }
}

ConsumptionDetail.propTypes = {
  contract: PropTypes.object
};

export default compose(
  withStyles(styles),
  withTranslation(['common', 'validation'])
)(ConsumptionDetail);
