import React from 'react';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import 'moment/locale/es';
import DialogActions from "@material-ui/core/DialogActions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { Close } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { theme } from '../../../config';
import { withTranslation } from "react-i18next";

class ConsumptionCertificateDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStartDate: moment(),
      selectedEndDate: moment(),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open === true) {
      this.setState({
        selectedStartDate: moment().subtract(1, 'year').startOf('month'),
        selectedEndDate: moment().startOf('month'),
      });
    }
  }

  handleClose = (doRequest=false) => {
    let { selectedStartDate, selectedEndDate } = this.state;
    if (!doRequest)
      selectedStartDate = selectedEndDate = false
    this.props.onClose(selectedStartDate, selectedEndDate);
  };

  handleDateChange = (selectedDate, which) => {
    if (which === 'start')
      this.setState({selectedStartDate: selectedDate});
    else
      this.setState({selectedEndDate: selectedDate});
  };

  render() {
    const { open, t } = this.props;
    const { selectedStartDate, selectedEndDate } = this.state;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog open={open} onClose={() => this.handleClose(false)} maxWidth={"md"}>
          <Grid container justify="space-between">
            <DialogTitle>{t('common:download_of', {what: t('common:consumption_certificate')})}</DialogTitle>
            <IconButton
              style={{
                marginRight: theme.spacing(1)
              }}
              onClick={() => this.handleClose(false)}
            >
              <Close/>
            </IconButton>
          </Grid>
          <DialogContent>
            <DialogContentText>
              {t('do', {what: t('point'), over: t('range_to_query')})}
            </DialogContentText>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{marginTop: theme.spacing(3)}}
            >
              <KeyboardDatePicker
                autoOk
                disableFuture
                variant="inline"
                inputVariant="outlined"
                label={t('common:date_start')}
                format="DD/MM/YYYY"
                value={selectedStartDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => this.handleDateChange(date, 'start')}
                style={{ margin: theme.spacing(1) }}
              />
              <KeyboardDatePicker
                autoOk
                disableFuture
                variant="inline"
                inputVariant="outlined"
                label={t('common:date_end')}
                format="DD/MM/YYYY"
                value={selectedEndDate}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => this.handleDateChange(date, 'end')}
                style={{ margin: theme.spacing(1) }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleClose(true)}
              variant="contained" disableElevation color="primary"
              disabled={!selectedStartDate && !selectedEndDate}
            >
              {t('download', {what: ''})}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    )
  }
}

export default withTranslation(['common', 'validation'])(ConsumptionCertificateDialog);
