import { Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { returnDescriptiveDate } from "../../../utils";
import { theme, i18n } from "../../../config";
import NumberFormat from "react-number-format";
import React from "react";

export const EnergyTooltip = ({ active, payload, showInvoiced }) => {
  if (active && payload) {
    return (
      <Card style={{minWidth: 150}} variant="outlined">
        <CardContent>
          <Typography variant="subtitle2">{`${returnDescriptiveDate(payload[0].payload.date)}`}</Typography>
          <Typography
            color="textPrimary"
            component="h2"
            variant="h6" style={{
              color: theme.palette.primary.dark,
              marginTop: 10
            }}>
            {`${i18n.t('common:consumed')}: `}
            <NumberFormat displayType="text" value={payload[0].payload.active_import} suffix={" kWh"} decimalSeparator={","} thousandSeparator={"."}/>
          </Typography>
          <Typography
            color="textPrimary"
            component="h2"
            variant="h6" style={{
              color: theme.palette.secondary.dark,
              marginTop: 10
            }}>
            {`${i18n.t('common:poured')}: `}
            <NumberFormat displayType="text"
                          value={payload[0].payload.active_export} suffix={" kWh"}
                          decimalSeparator={","} thousandSeparator={"."}/>
          </Typography>
          {
            showInvoiced && <Typography
              color="textPrimary"
              component="h2"
              variant="body1" style={{
              color: payload[0].payload.cch_fact === 1 ?
                theme.palette.tertiary.dark : theme.palette.tertiary.contrastText,
              marginTop: 10
            }}>
              {
                payload[0].payload.cch_fact === 1 ?
                  i18n.t('common:invoiced_measure_affirmative')
                  :
                  i18n.t('common:invoiced_measure_negative')
              }

            </Typography>
          }
        </CardContent>
      </Card>
    );
  }
};