import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import _, { toInteger } from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import { Avatar, Grid, Paper } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Fade from "@material-ui/core/Fade";
import MD5 from "crypto-js/md5";
import { InfoContainer, LoadingSkeletons } from "../../components";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import TableContainer from '@material-ui/core/TableContainer';
import { history } from '../../config';
import StatusIndicator from "./components/StatusIndicator";
import NewComment from "./components/NewComment";
import { openGlobalSnackbar, fetchCRMCaseDetail, downloadAttachment, openCRMMessageDialog } from '../../actions';
import Typography from '@material-ui/core/Typography';
import saveAs from 'file-saver';
import marked from 'marked';

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  table: {
    margin: "5px 0 0 20px",
    padding: "0 20px 0 0"
  },
  tableCell: {
    "&:hover": {
      backgroundColor: "rgba(1,1,1,0.0625)",
      cursor: "pointer",
    }
  },
  downloadButton: {
    "$tableCell:hover &": {
      opacity: "1.0",
    },
    opacity: "0.25",
    marginRight: "10px",
    marginTop: "5px",
  },
  firstChip: {
    margin: '0.5vh',
  },
  answerButton: {
    margin: "5px"
  },
  statusBarPending: {
    backgroundColor: "#FCBB6D",
    color: 'white'
  },
  statusBarAccepted: {
    backgroundColor: "#4AB19D",
    color: 'white'
  },
  statusBarDeclined: {
    backgroundColor: "#EF3D59",
    color: 'white'
  }
});

const Gravatar = ({ email, alt }) => (
  <Avatar
    alt={alt}
    src={`https://www.gravatar.com/avatar/${MD5(email).toString()}?d=identicon`}
  />
);

const Attachment = ({name, date, theme}) => {
  return(
    <Grid container direction="column" justify="center" alignItems="flex-start">
      <Grid item container justify="flex-start" alignItems="center">
        <Grid item>
          <GetAppIcon size={"small"} className={theme}/>
        </Grid>
        <Grid item>
          <div>
            {name}
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" display="block" gutterBottom style={{color: "gray"}}>
          {date}
        </Typography>
      </Grid>
    </Grid>
  )
};

class CRMDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentsPerRow: 5,
      answerOpen: false, 
      errorSending: false,
      errorMessage: '',
      sendingMessage: false,
      message: '',
    };
  }

  calculateWidth = () => {
    /* 448 és una unitat arbitrària per dividir els attachments en finestres més petites
      segons la mida d'una pantalla. Exemple: 1920 / 448 = 4
    */
    this.setState({
      attachmentsPerRow: Math.ceil(window.innerWidth / 448)
    });
  }

  componentDidMount(){
    window.addEventListener("resize", this.calculateWidth);
    if(_.get(this.props, 'caseData.id', false) !== toInteger(this.props.match.params.id)){
      this.props.fetchCRMCaseDetail(this.props.match.params.id)
      .then(
        ([statusCode,]) => {
          if (statusCode === 401) {
            this.props.openGlobalSnackbar(this.props.t('validation:error_fetching_data'), true);
          }
        }
      );
    }
  }
  
  componentWillUnmount(){
    window.removeEventListener("resize", this.calculateWidth);
  }

  downloadAttachment(attachment){
    this.props.downloadAttachment(
      this.props.match.params.id, 
      attachment.id
    ).then(
      response => {
        if(response[0] === 200){
          saveAs(response[1].content, response[1].name);
        }
      }
    );
  }

  renderAttachments(){
    const { classes, caseData } = this.props;
    const attachments = _.get(caseData, 'attachments');
    return(
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {
                _.chunk(attachments, this.state.attachmentsPerRow)
                .map(
                  (slicedAttachments, idx) => (
                    <TableRow key={idx}>
                    {
                      slicedAttachments.map(
                        (cell, idx) => (
                          <TableCell key={idx} className={classes.tableCell} onClick={() => {this.downloadAttachment(cell)}}>
                            <Attachment name={cell.name} date={cell.date} theme={classes.downloadButton} />
                          </TableCell>
                        )
                      )
                    }
                    </TableRow>
                  )
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  renderDescription() {
    const { t, caseData } = this.props;
    const mail = _.get(caseData, 'mails[0]');
    return (
      <Paper style={{ padding: "10px 10px", margin: 20, marginBottom: 0 }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            {
              _.get(mail, 'from.customer', false) 
              ?
              <Gravatar email={_.get(mail, 'from.email')} />
              :
              <AccountCircleIcon style={{ fontSize: 45 }} />
            }
          </Grid>
          <Grid item xs zeroMinWidth>
            <h4 style={{ margin: 0, textAlign: "left" }}>
            {
              _.get(mail, 'from.customer', false) 
              ?
              _.get(mail, 'from.name')
              :
              <span>{t("common:administrator")}</span>
            }
            </h4>
            <p style={{ textAlign: "left" }}>
              {_.get(mail, 'message')}
            </p>
            <p style={{ textAlign: "left", color: "gray" }}>
              {_.get(mail, 'date')}
            </p>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  renderConversation() {
    const { t, caseData } = this.props;
    const mails = _.get(caseData, 'mails');
    if (!_.isElement(mails)){
      return _.drop(mails).map(
        (mail, ids) => {
          return (
            <Paper key={ids} style={{ padding: "10px 10px", marginTop: 20 }}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  {
                    _.get(mail, 'from.customer', false) 
                    ?
                    <Gravatar email={_.get(mail, 'from.email')} />
                    :
                    <AccountCircleIcon style={{ fontSize: 45 }} />
                  }
                </Grid>
                <Grid item zeroMinWidth>
                  <h4 style={{ margin: 0, textAlign: "left" }}>
                    {
                      _.get(mail, 'from.customer', false) 
                      ?
                      _.get(mail, 'from.name')
                      :
                      <span>{t("common:administrator")}</span>
                    }
                  </h4>
                  <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: marked(_.get(mail, 'message')) }} />
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {_.get(mail, 'date')}
                  </p>
                </Grid>
              </Grid>
            </Paper>
          );
        }
      );
    } else {
      return null;
    }
  }

  renderAnswerBox() {
    const { t, classes, caseData } = this.props;
    const name = _.get(caseData, 'mails[0].from.name', 'undefined');
    const email = _.get(caseData, 'mails[0].from.email', 'undefined');

    const openAnswerButton = (
      <Button
        variant="contained"
        color="primary"
        className={classes.answerButton}
        startIcon={<QuestionAnswerIcon />}
        onClick={() => {this.props.openCRMMessageDialog()}}
      >
        {t("common:answer")}
      </Button>
    )

    return( 
      this.props.answerOpen 
      ? 
      <NewComment name={name} email={email}/> 
      : 
      openAnswerButton 
    );      
  }

  getCaseType () {
    switch (
      this.props.match.params.type || this.props.fetchedSection
      ) {
        case 'solicitudes':
          return 'SOL_CRM';
        case 'autoconsumo':
          return 'AUTOCONS';
        case "reclamacio":
          return "RECLAMACIO";
        case "evehicle":
          return "EVEHICLE";
        default:
          return 'SOL_CRM';
      }
  }

  returnButton() {
    const { classes, t } = this.props;
    return (
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        onClick={() => {history.push(`/solicitudes/${this.props.match.params.type}`)}}
      >
        {t("common:crm_go_back")}
      </Button>
    );
  }
  
  renderTitle(){
    const { classes, caseData } = this.props;
    return (
      <div>
        {_.get(caseData,'data.title')}
        <Chip 
            label={_.get(caseData, 'data.section')} 
            variant="outlined" 
            size="small"
            color="primary"

            className={classes.firstChip}
          />
          {_.get(caseData, 'data.category') &&
            <Chip
            label={_.get(caseData, 'data.category')} 
            variant="outlined"
            size="small"
            color="secondary"
            />
          }
      </div>
    );
  }

  render() {
    const { classes, t, caseData, loadingCase } = this.props;
    if(!loadingCase && caseData){
      return (
        <Fade in={true}>
            <InfoContainer
              maxWidth={"lg"}
              title={this.renderTitle()}
              subtitle={t("common:crm_detail_subtitle")}
              className={classes.container}
              actionButton={this.returnButton()}
            >
              {loadingCase ?
                <LoadingSkeletons fullHeight/>
                :
                <div>
                  <StatusIndicator status={caseData.status}/>
                  {this.renderDescription()}
                  {this.renderAttachments()}
                  <Grid 
                    container 
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item xs={1}></Grid>
                    <Grid 
                      item
                      xs={10}
                    >
                      {this.renderConversation()}
                      {this.renderAnswerBox()}
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </div>
              }
            </InfoContainer>
        </Fade>
      );
    } else {
      return(
        <Fade in={true}>
            <InfoContainer
              maxWidth={"lg"}
              title={"Error"}
              subtitle={t("common:crm_error_no_info")}
              className={classes.container}
              actionButton={this.returnButton()}
            >
            </InfoContainer>
        </Fade>
      );
    }
  }
}

const mapDispatchToProps = {
  fetchCRMCaseDetail,
  downloadAttachment,
  openCRMMessageDialog,
  openGlobalSnackbar
};

const mapStateToProps = state => {
  return {
    caseData: _.get(state, 'CRMCases.case', false),
    loadingCase: _.get(state, 'CRMCases.loadingCRMCase', true),
    isSignedIn: state.user.isSignedIn,
    userName: state.user.name,
    answerOpen: _.get(state, 'CRMCases.case.answerOpen', false),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common', 'validation']),
  withRouter
)(CRMDetail);
