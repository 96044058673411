import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import { history } from '../../config';
import { InfoContainer } from "../../components";
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MultiUploadButton, RequirementDoc } from "./components";
import {
  fetchCRMCaseSections,
  fetchCRMCaseSectionsAttachmentsList,
  fetchCRMCaseCategories,
  fetchCRMContracts,
  cleanSectionsAttachmentsList,
  downloadCRMCaseSectionsAttachment,
  sendNewCRMCase,
  fetchCRMCases,
} from "../../actions";
import saveAs from 'file-saver';
import {
  Grid,
  Button,
  FormControlLabel,
  Fade,
  MenuItem,
  TextField,
  Checkbox,
  Typography,
} from "@material-ui/core";
import Settings from "../../settings.yml";
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0,
  },
  header: {
    textAlign: "center",
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
});

class CRMCreate extends React.Component {
  constructor(props) {
    super(props);

    let sectionFromProps =
      _.get(props, "location.state.section", null) ||
      _.get(props, "state.section", null);
    this.state = {
      sectionCode: sectionFromProps,
      sectionFromProps: !!sectionFromProps,
      sectionDescription: "",
      section: "",
      sectionName: "",
      category: "",
      validationErrors: {},
      anchorEl: null,
      checkedRelatedContract: false,
      requiredAttachments: 0,
      requiredUploadedAttachments: 0,
      uploadedAttachments: [],
      additionalAttachments: []
    };
  }

  componentDidMount() {
    this.props.cleanSectionsAttachmentsList();
    if (
      _.isEmpty(this.props.sections) ||
      !(this.getCaseType() in this.props.sections)
    ) {
      this.props.fetchCRMCaseSections(this.getCaseType()).then(() => {
        this.selectDefaultSection();
      });
    } else {
      this.selectDefaultSection();
    }
    const limit = parseInt(Settings.contractsLimitPerGet);
    this.props.fetchCRMContracts(0, limit);
  }

  componentDidUpdate(prevProps){
    if(this.props.sectionAttachments !== prevProps.sectionAttachments){
      let newRequiredAttachments = 0;
      this.props.sectionAttachments.map((att, idx) => {
        if (att.required === 'must_fill'){
          newRequiredAttachments++;
        }
        return null;
      })
      this.setState({
        requiredAttachments: newRequiredAttachments,
        uploadedAttachments: _.fill(Array(this.props.sectionAttachments.length), null)
      });
    }
  }

  selectDefaultSection() {
    let sectionsLength = _.get(this.props, `sections[${this.getCaseType()}].data.length`, 0);
    if (sectionsLength === 1) {
      this.setState({ section: _.get(this.props, `sections[${this.getCaseType()}].data[0].id`, "") });
    }
  }

  getCaseType() {
    switch (
      this.props.match.params.type
    ) {
      case "genericas":
        return "SOL_CRM";
      case "autoconsumo":
        return "AUTOCONS";
      case "reclamacio":
        return "RECLAMACIO";
      case "evehicle":
        return "EVEHICLE";
      default:
        return "SOL_CRM";
    }
  }

  handleChangeSection = (event) => {
    let sectionData = this.getSectionDataFromValueId(event.target.value);
    this.setState(
      {
        section: event.target.value, 
        sectionDescription: sectionData.selectedDescription,
        sectionName: sectionData.selectedName,
      },
      () => {
        this.updateCategoriesAttachments(event)
      }
    );
  };

  updateCategoriesAttachments = (event) => {
    this.props.fetchCRMCaseCategories(event.target.value)
    this.props.fetchCRMCaseSectionsAttachmentsList(event.target.value)
  }

  handleChangeCategory = (event) => {
    this.setState({ category: event.target.value });
  };

  handleChangePolicy = (event, value) => {
    this.setState({ policy: (value !== null) ? value.id : null });
  };

  handleChangeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  handleChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  handleChangeRelatedContract = (event) => {
    this.setState({ checkedRelatedContract: event.target.checked });
  };

  handleUpload = (file) => {
    this.setState({ additionalAttachments: file });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      'polissa_id': this.state.policy,
      'categ_id': this.state.category,
      'title': this.state.title,
      'section_id': this.state.section,
      'message': this.state.description,
      'attachments': _.concat(_.compact([...this.state.uploadedAttachments]), this.state.additionalAttachments)
    }
    this.props.sendNewCRMCase(data)
    .then(
      response => {
        if(response[0] === 200){
          this.props.fetchCRMCases()
            .then(() => history.push(`/solicitudes/${this.props.match.params.type}/${response[1].id}/`));
        };
      }
    );
    /*let validationErrors = validate(this.state, schema);
    this.setState({
      isValid: !validationErrors,
      validationErrors: validationErrors || {}
    });*/
    /*this.sendNewCRMCase({
      'polissa_id': 
    })*/
  }
  
  downloadAttachment(attachment){
    this.props.downloadAttachment(
      this.props.match.params.id, 
      attachment.id
    ).then(
      response => {
        console.log(response);
        if(response[0] === 200){
          saveAs(response[1].content, response[1].name);
        }
      }
    );
  }

  handleClickModel = (event) => {
    this.setState({anchorEl: event.target});
  };

  handleDownloadSectionAttachment = (id) => {
    this.props.downloadCRMCaseSectionsAttachment(id).then(
      response => {
        console.log(response);
        if(response[0] === 200){
          saveAs(response[1].content, response[1].name);
        }
      }
    );
    this.handleCloseModel()
  }

  handleCloseModel = () => {
    this.setState({anchorEl: null});
  };

  getSectionDataFromValueId(value) {
    const { sections } = this.props;
    let selectedDescription = '';
    let selectedName = '';
    sections[this.getCaseType()].data.forEach(element =>  {
      if (element.id === value) {
        selectedName = element.name;
        selectedDescription = element.ov_description;
      }
    });
    return {selectedDescription, selectedName};
  }

  handleUploaded = (file, index, required) => {
    if (file){
      let newUploadsArray = [...this.state.uploadedAttachments];
      newUploadsArray.splice(index, 1, file);
      this.setState({uploadedAttachments: newUploadsArray});
      if(required){
        this.setState({requiredUploadedAttachments: this.state.requiredUploadedAttachments + 1});
      }
    } else {
      this.setState({uploadedAttachments: _.pullAt(this.state.uploadedAttachments), index});
      if(required){
        this.setState({requiredUploadedAttachments: this.state.requiredUploadedAttachments - 1});
      }
    }
  }

  renderForm() {
    const { t, sections, categories, contracts } = this.props;
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <div style={{ margin: "0 1%", padding: "1%" }}>
          {_.get(sections, `${this.getCaseType()}.data`, false) && _.get(sections, `${this.getCaseType()}.data.length`, 0) > 1 ? (
            <div>
              <TextField
                id="select_section"
                select
                label={t("common:section")}
                value={this.state.section}
                fullWidth
                onChange={this.handleChangeSection}
                required
                style={{ marginBottom: "3%" }}
              >
                {_.get(sections, `${this.getCaseType()}.data`, []).map((option, idx) => (
                  <MenuItem key={idx} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {this.state.sectionDescription && this.state.sectionName && (
                <div>
                  <Typography variant="h5" gutterBottom>
                    {this.state.sectionName}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {this.state.sectionDescription}
                  </Typography>
                </div>
              )}
              <br />
              <Grid container spacing={2}>
                {this.props.sectionAttachments.map(
                  (att, idx) => (
                    <Grid item lg={3} md={6} key={att.id}>
                      <RequirementDoc
                        downloadFunction={this.handleDownloadSectionAttachment}
                        attachmentId={att.id}
                        fileLabel={att.attachment_name}
                        required={att.required}
                        handleUploaded={this.handleUploaded}
                        index={idx}
                        downloadLabel={att.required !== 'information' ? t("common:template") : t("common:download", {what: ''})}
                      ></RequirementDoc>
                  </Grid>
                  )
                )}
              </Grid>
              <br />
            </div>
          ) : null}

          {!_.isEmpty(categories.data) && (
            <TextField
              id="select_category"
              select
              label={t("common_category")}
              value={t("common:" + this.state.category)}
              fullWidth
              onChange={this.handleChangeCategory}
              style={{ marginBottom: "3%" }}
              required
            >
              {categories.data.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {!_.isEmpty(contracts) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedRelatedContract}
                  onChange={this.handleChangeRelatedContract}
                  name="checkedRelatedContract"
                  color="primary"
                />
              }
              label={t("crm_related_to_contract")}
            />
          )}

          {!_.isEmpty(contracts) && this.state.checkedRelatedContract && (
            <Autocomplete
              id="select_policy"
              options={_.toArray(contracts)}
              onChange={this.handleChangePolicy}
              getOptionLabel={(option) => {return (option === "") ? "" : (option.name + " - " + option.cups.full_address) }}
              style={{ marginBottom: "3%" }}
              renderInput={(params) =>
                <TextField 
                  {...params} 
                  label={t("common:contract_singular")}
                />
              }
            />
          )}

          <TextField
            id="asunto"
            label={t("common:subject")}
            fullWidth
            required
            onChange={this.handleChangeTitle}
            style={{ marginBottom: "3%" }}
          ></TextField>

          <TextField
            id="descripcion"
            label={t("common:description")}
            fullWidth
            multiline
            rows={8}
            required
            onChange={this.handleChangeDescription}
            style={{marginBottom: '3%'}}
          >
          </TextField>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <MultiUploadButton
                min={1}
                max={3}
                label={t("crm_attachment_add")}
                onChange={this.handleUpload}
                value={this.state.attachment}
                anotherLabel={t("crm_attachment")}
                removeLabel={t("crm_attachment_remove")}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                endIcon={this.props.sendingCRMCase ? <CircularProgress size={20}/> : <SendIcon />}
                type="submit"
                disabled={
                  !(
                    !this.props.sendingCRMCase &&
                    this.state.section &&
                    this.state.title &&
                    this.state.description &&
                    this.state.requiredAttachments === this.state.requiredUploadedAttachments
                  )
                }
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                {this.props.sendingCRMCase ? t("common:sending") : t("common:send")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }

  render() {
    const { classes, t } = this.props;
    return (
      <Fade in={true}>
        <InfoContainer
          maxWidth={"lg"}
          title={t("crm_new_case")}
          subtitle={t("contract_list_subtitle")}
          className={classes.container}
          goBackArrow={`/solicitudes/${this.props.match.params.type}`}
        >
          {this.renderForm()}
        </InfoContainer>
      </Fade>
    );
  }
}

const mapDispatchToProps = {
  fetchCRMCaseSections,
  fetchCRMCaseSectionsAttachmentsList,
  fetchCRMCaseCategories,
  fetchCRMCases,
  fetchCRMContracts,
  downloadCRMCaseSectionsAttachment,
  cleanSectionsAttachmentsList,
  sendNewCRMCase
};

const mapStateToProps = (state) => {
  return {
    sections: _.get(state, 'CRMCases.sections', {}),
    sectionAttachments: _.get(state, 'CRMCases.sections_attachments', []),
    fetchedSection: _.get(state, "CRMCases.fetchedSection", null),
    categories: _.get(state, 'CRMCases.categories', {}),
    contracts: _.get(state, 'contracts.items', {}),
    sendingCRMCase: _.get(state, 'CRMCases.sendingCRMCase', false),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common', 'validation']),
)(CRMCreate);
