import { FETCH_CONTRACT_LIST, FETCH_CONTRACT, RESET_STORE } from "../actions/types";
import { INITIAL_CONTACT_STATE } from "./index";

import _ from 'lodash';


export default (state = INITIAL_CONTACT_STATE, action) => {
  switch (action.type) {
    case RESET_STORE:
      return INITIAL_CONTACT_STATE;
    case FETCH_CONTRACT_LIST:
      return {
        ...state,
        message: action.payload.message,
        items: _.mapKeys(_.get(action.payload.data, "items", []), 'name'),
        n_items: _.get(action.payload.data, "n_items", 0),
        offset: _.get(action.payload.data, "offset", 0)
      };
    case FETCH_CONTRACT:
      return {
        ...state,
        message: action.payload.message,
        items: _.mapKeys(_.get(action.payload.data, "items", []), 'name')
      };
    default:
      return state;
  }
};