import {applyMiddleware, compose, createStore} from "redux";
import appReducer from "../reducers";
import reduxThunk from "redux-thunk";

export const configureStore = (initialState) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    appReducer, initialState, composeEnhancers(applyMiddleware(reduxThunk))
  );
};

export const store = configureStore();