import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle, Input, Lock, MailOutline, Eco, Error, EvStation } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { signOut } from "../../../../actions";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { history } from '../../../../config';
import { useMediaQuery } from "react-responsive";
import Settings from "../../../../settings.yml";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.contrastText
  },
}));

const UserSection = props => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    props.signOut();
  };

  const handleChangePassword = () => {
    history.push("/reset")
  };

  const handleCRMRoute = (ruta) => {
    history.push(`/solicitudes/${ruta}`)
  };

  const getCRMViews = (props) => {
    let viewsArray = [
      {route: 'genericas', icon: <MailOutline fontSize="small"/>},
      {route: 'autoconsumo', icon: <Eco fontSize="small"/>},
    ];

    if(props && props.reclamacions){
      viewsArray.push({route: 'reclamacio', icon: <Error fontSize="small"/>});
    }

    if(props && props.evehicle){
      viewsArray.push({route: 'evehicle', icon: <EvStation fontSize="small"/>});
    }

    if(Settings.crm_enabled){
      return (viewsArray.map((element, idx) => 
        <MenuItem onClick={() => {handleCRMRoute(element.route)}} key={idx}>
          <ListItemIcon>
            {element.icon}
          </ListItemIcon>
          <ListItemText primary={t(`crm_distri_${element.route}`)}/>
        </MenuItem>
      ))
    } else {
      return [];
    }
  }

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const crmViews = getCRMViews(props);

  return (
    <div className={classes.root}>
      <Button
        aria-controls="menu-appbar"
        aria-haspopup="true"
        size="large"
        onClick={handleMenu}
        endIcon={<AccountCircle className={classes.title}/>}
      >
        {
          !isTabletOrMobile &&
            <Typography
              variant="subtitle1"
              className={classes.title}
            >
              {props.userName}
            </Typography>
        }
      </Button>
      <Menu
        id="menu-appbar"
        elevation={3}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
        styles={{border: '1px solid #d3d4d5'}}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Input fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={t('logout')}/>
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <Lock fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={t('change_password')}/>
        </MenuItem>
        {crmViews}
      </Menu>
    </div>
  );
};


const actionCreators = {
  signOut: signOut,
};

const mapStateToProps = state => {
  return {
    userName: state.user.name
  };
};

export default connect(
  mapStateToProps, actionCreators
)(UserSection);