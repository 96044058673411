import React from 'react';
import {theme} from "../../../config";
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import {withStyles} from "@material-ui/styles";
import * as PropTypes from "prop-types";
import {DateAxisTick} from "./DateAxisTick";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import { round } from '../../../utils';

const styles = theme => ({
  cchContainer: {
    userSelect: 'none',
  },
  lineColor: {
    color: theme.palette.secondary.main
  },
  activeDotColor: theme.palette.secondary.dark,
  dotColor: 'white'
});

class BarChart extends React.Component {

  initialState(chartData) {
    const refLine = round(this.props.referenceLine * 1.1, 1) || 0;
    const top = Math.max(this.getMaxChartData(chartData), refLine);
    return {
      chartData: chartData,
      left : 'dataMin',
      right : 'dataMax',
      animation : true,
      bottom: 0,
      top: round(top * 1.1, 1)
    }
  }

	constructor(props) {
    super(props);
    const { chartData } = props;
    this.state = this.initialState(chartData);
  }

  getMaxChartData(chartData) {
    let max = 0;
    chartData.forEach(
      row => {
        // The key attr is the name of the series. For example, for a CCH chart
        // the series may be the active import and active export values.
        // And the key is "active_import" and "active_export".
        this.props.series.forEach(
          series => {
            max = row[series.key] > max ? row[series.key] : max
          }
        )
      }
    );
    return max;
  }

  renderChart() {
	  const mainStrokeColor = theme.palette.primary.main;
	  const secStrokeColor = theme.palette.secondary.main;
	  const { chartData, bottom, top } = this.state;
    const {
      classes, customTooltip, series, xAxisTickFormatter,
      yAxisTickFormatter, referenceLine, descriptor, t, margin
    } = this.props;

	  if (chartData) {
      return (
        <RechartsBarChart
          data={chartData}
          className={classes.cchContainer}
          margin={margin}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            allowDataOverflow={true}
            interval="preserveStartEnd"
            dataKey={descriptor}
            type="category"
            tick={<DateAxisTick tickFormatter={xAxisTickFormatter}/>}
            tickMargin={10}
          />
          {
            series.map(
              ({label, key}, idx) => {
                const orientation = idx === 0 ? "left" : "right";
                const leftProps = {
                  position: idx === 0 ? "insideTopLeft" : "insideTopRight",
                };
                return <YAxis
                  allowDataOverflow={true}
                  type="number"
                  domain={[bottom, top]}
                  key={idx.toString()}
                  yAxisId={idx.toString()}
                  tickCount={15}
                  tickFormatter={yAxisTickFormatter}
                  offset={0}
                  label={{ value: label, dy: -40, ...leftProps }}
                  interval="preserveStartEnd"
                  stroke={idx === 0 ? mainStrokeColor : secStrokeColor}
                  orientation={orientation}
                />
              }
            )
          }
          <Tooltip
            content={customTooltip}
            offset={20}
          />
          {
            series.map(
              ({label, key}, idx) => <Bar
                yAxisId={idx.toString()}
                type="number"
                key={idx.toString()}
                dataKey={key}
                fill={idx === 0 ? mainStrokeColor : secStrokeColor}
              />
            )
          }
          {
            referenceLine &&
              <ReferenceLine
                y={referenceLine}
                label={{
                  position: 'top',
                  value: `${t('contracted_power')} ${referenceLine} kW`,
                  fontSize: 14
                }}
                stroke="#b71c1c"
                strokeWidth={2}
              />
          }
        </RechartsBarChart>
      )
    }
    else {
      return <React.Fragment/>;
    }
  }

  render() {
    const { width, aspect } = this.props;

    return (
      <ResponsiveContainer aspect={aspect} width={width}>
        {this.renderChart()}
      </ResponsiveContainer>
    );
  }
}

BarChart.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aspect: PropTypes.number,
  chartData: PropTypes.array,
  series: PropTypes.array,
  descriptor: PropTypes.string,
  xAxisTickFormatter: PropTypes.func,
  yAxisTickFormatter: PropTypes.func,
  referenceLine: PropTypes.number
};

export default compose(
  withStyles(styles),
  withTranslation(['common', 'validation'])
)(BarChart);