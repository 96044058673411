import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import {PrivateRoute} from "./PrivateRoute";

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.body1,
    color: "rgba(0, 0, 0, 0.5)",
  },
  value: {
    ...theme.typography.body1,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
}));

const TextFieldReadOnly = props => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <label className={classes.label}>{props.label}</label>
      <div className={classes.value}>
      {
        props.children
      }
      </div>
    </div>
  );
};

PrivateRoute.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default TextFieldReadOnly;
