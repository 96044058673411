import React from 'react';
import * as PropTypes from "prop-types";
import RouteWithLayout from "./RouteWithLayout";
import { actionLoginUserSuccess, logoutUser } from "../actions";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../config";
import { UserService } from "../service";
import { Redirect } from "react-router-dom";
import { actionChangeLocale } from "../actions";
import { getLangByToken } from "../i18n";
import { isDefined } from "../utils";
import Cookies from "js-cookie";
import _ from 'lodash';

export class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: !!this.props.isSignedIn,
    };
  }

  /*
  * Try to get the token from the URL query params, and if it's not found, we'll
  * look at the local storage
  * */
  getToken = () => {
    let token = null;
    const params = _.get(this.props, 'location.search', '')
    if (params) {
      history.push({search: ''})
      const urlParams = new URLSearchParams(params);
      token = urlParams.get('token');
    }
    else {
      token = Cookies.get('token');
    }
    return token;
  }

  componentDidMount() {
    if (!this.props.isSignedIn) {
      const token = this.getToken();
      if (isDefined(token)) {
        const userService = new UserService();
        userService.checkToken(token)
        .then(
          res => {
            if (res) {
              this.props.loginUserSuccess(token);
              this.props.changeLocale(getLangByToken(token));
            } else {
              this.props.logoutUser();
              history.push("/acceso");
            }
            this.setState({isLoaded: true})
          }
        )
        .catch(
          error => {
            console.debug("Error checking token", error);
            this.setState({isLoaded: true});
          }
        )
      } else {
        this.setState({isLoaded: true});
      }
    }
  }

  render() {
    let {
      component: Component,
      layout: Layout,
      isSignedIn,
      location,
      ...rest
    } = this.props;

    const {isLoaded} = this.state;
    if (isLoaded && isSignedIn) {
      if (Layout) {
        return <RouteWithLayout {...rest} layout={Layout}
                                component={Component}/>;
      } else {
        return <Component {...rest}/>
      }
    } else if (isLoaded && !isSignedIn) {
      console.debug("Not signed in, redirecting to login...")
      return <Redirect to={{pathname: "/acceso", state: {from: location}}}/>
    } else {
      return (
        <div style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '25%',
        }}>
          <CircularProgress
            disableShrink
            size={60}
            thickness={4}
          />
        </div>
      )
    }
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.node,
  layout: PropTypes.node
};

const mapStateToProps = state => {
  return {
    token: state.user.token,
    isSignedIn: state.user.isSignedIn
  };
};

export default connect(mapStateToProps, {
  loginUserSuccess: actionLoginUserSuccess,
  changeLocale: actionChangeLocale,
  logoutUser
})(PrivateRoute);