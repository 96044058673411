import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import CRMListItem from "./components/CRMListItem";
import { withTranslation } from "react-i18next";
import { InfoContainer, LoadingSkeletons } from "../../components";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from '@material-ui/core/Button';
import { history } from '../../config';
import { openGlobalSnackbar, fetchCRMCases } from '../../actions';

import { InfoContainerBody } from "../../components/InfoContainerBody";

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
});

class CRMList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true
    };
  }

  componentDidMount() {
    this.fetchCRMCases();
  }

  componentDidUpdate() {
     if(
      (this.getCaseType() !== this.props.fetchedSectionCases ||
      _.isEmpty(this.props.CRMCases.items)) &&
      !_.get(this.props, 'CRMCases.loadingCRMCases', false)
      ){
       this.fetchCRMCases();
     }
  }

  getCaseType() {
    switch (
      this.props.match.params.type
    ) {
      case "genericas":
        return "SOL_CRM";
      case "autoconsumo":
        return "AUTOCONS";
      case "reclamacio":
        return "RECLAMACIO";
      case "evehicle":
        return "EVEHICLE";
      default:
        return "SOL_CRM";
    }
  }

  fetchCRMCases() {
    if(this.props.fetchedSectionCases !== this.getCaseType()){
      this.props.fetchCRMCases(this.getCaseType())
      .then(
        ([statusCode,]) => {
          if (statusCode === 401) {
            this.props.openGlobalSnackbar(this.props.t('validation:error_fetching_data'), true);
          }
        }
      );
    }
  }

  renderListItems () {
    if(!_.isEmpty(this.props.CRMCases.items)){
      return this.props.CRMCases.items.map(
        (el, _) => (
         <CRMListItem
            key={el.id}
            detailCase={el}
            handleClick={() => {history.push(`/solicitudes/${this.props.match.params.type}/${el.id}/`);}}
            t={this.props.t}
          />
        )
      )
    }
  }

  renderTable() {
    return (
      <Table>
        <TableBody>
          {this.renderListItems()}
        </TableBody>
      </Table>
    )
  }

  newCaseButton() {
    const { t } = this.props;
    const section = _.get(this, "props.location.state.section", null);
    return (
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => {history.push(`/solicitudes/${this.props.match.params.type}/crear`, { section })}}
      >
        {t('common:crm_new_case')}
      </Button>
    );
  }

  render() {
    const { classes, t } = this.props;

    return (
      <Fade in={true}>
        <InfoContainer
          maxWidth={"lg"}
          title={t(`common:crm_list_title_${this.props.match.params.type}`)}
          subtitle={t("common:crm_requested_subtitle")}
          className={classes.container}
          actionButton={_.isEmpty(this.props.CRMCases.items) ? null : this.newCaseButton()}
          goBackArrow={'/inicio'}
        >
          { _.get(this.props, 'CRMCases.loadingCRMCases', false) ?
            <LoadingSkeletons fullHeight/>
            :
            (!_.isEmpty(this.props.CRMCases.items) ?
            this.renderTable()
            :
            <InfoContainerBody
              maxWidth={"lg"}
              subtitle={t("common:crm_without_data")}
              className={classes.container}
              actionButton={this.newCaseButton()}>
            </InfoContainerBody>
            )
          }
        </InfoContainer>
      </Fade>
    );
  }
}

const mapStateToProps = state => {
  return {
    CRMCases: _.get(state, 'CRMCases', {}),
    fetchedSectionCases: _.get(state, 'CRMCases.fetchedSectionCases', null),
    isSignedIn: state.user.isSignedIn,
    userName: state.user.name
  };
};

const mapDispatchToProps = {
  fetchCRMCases,
  openGlobalSnackbar,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common', 'validation'])
)(CRMList);
