import React from 'react'
import { Router } from 'react-router'
import { Provider } from 'react-redux'

const ProviderWrapper = ({ children, store, history }) => (
  <Provider store={store}>
    <Router history={history}>
      { children }
    </Router>
  </Provider>
);

export default ProviderWrapper;
