import _ from "lodash";
import ContractService from "../service/ContractService";
import {
  FETCH_CONTRACT_LIST,
  FETCH_CONTRACT
} from "./types";

import {
  FETCH_CONTRACT_SUCCESSFUL_MSG,
  FETCH_CONTRACT_FAILURE_MSG
} from './messages';
import { parseResponse } from "../service";

export const actionSuccessfulDataFetch = (actionType, data) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: FETCH_CONTRACT_SUCCESSFUL_MSG
    }
  }
};

export const actionFailedDataFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_CONTRACT_FAILURE_MSG
    }
  }
};

export const fetchContract = (name) => async (dispatch, getState) => {
  const contractService = new ContractService();
  return await contractService.getContract(name)
  .then(
    response => {
      dispatch(
        actionSuccessfulDataFetch(FETCH_CONTRACT, _.get(response, 'data', []))
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedDataFetch(FETCH_CONTRACT))
      return parseResponse(error);
    }
  )
};

export const fetchContracts = (offset, limit) => async (dispatch, getState) => {
  const contractService = new ContractService();
  return await contractService.getContractList(offset, limit)
  .then(
    response => {
      dispatch(
        actionSuccessfulDataFetch(FETCH_CONTRACT_LIST, _.get(response, 'data', []))
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedDataFetch(FETCH_CONTRACT_LIST))
      return parseResponse(error);
    }
  )
};

export const fetchCRMContracts = (offset, limit) => async (dispatch, getState) => {
  const contractService = new ContractService();
  return await contractService.getCRMContractList(offset, limit)
  .then(
    response => {
      dispatch(
        actionSuccessfulDataFetch(FETCH_CONTRACT_LIST, _.get(response, 'data', []))
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedDataFetch(FETCH_CONTRACT_LIST))
      return parseResponse(error);
    }
  )
};
