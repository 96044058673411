import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { UserService } from "../../service";
import validate from 'validate.js';
import { i18n, theme } from "../../config";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validi18nKey } from "../../utils";

const schema = {
  currentPassword: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 255
    }
  },
  newPassword1: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 255
    }
  },
  newPassword2: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    equality: {
      attribute: "newPassword1",
      message: "^" + i18n.t("validation:passwords_must_be_equal")
    },
    length: {
      maximum: 255
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "5%",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    flexDirection: "column",
  },
  form: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 65,
    flexBasis: 300,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  mainButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ResetPassword = props => {

  const classes = useStyles();
  const {t} = useTranslation(['common', 'validation']);
  const userService = new UserService();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    validationErrors: {},
    serverErrors: {}
  });

  const [passwordChanged, setPasswordChanged] = useState(false);
  const [snackbarOpen, toggleSnackbar] = useState(false);
  const [snackbarInfo, showSnackbarInfo] = useState("");
  const [resettingPass, setResettingPass] = useState(false);
  const [showPassword0, setShowPassword0] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    let validationErrors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: !validationErrors,
      validationErrors: validationErrors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handlePasswordReset = async event => {
    event.preventDefault();

    setResettingPass(true);
    let [statusCode, validationResult] = await userService.resetPassword(
      formState.values.currentPassword, formState.values.newPassword1
    );
    if (200 <= statusCode && statusCode < 300) {
      setPasswordChanged(true);
    } else if (400 <= statusCode && statusCode < 500) {
      let errorKey = 'validation:incorrect_form';
      if ('errors' in validationResult)
        validationResult = validationResult.errors;
      toggleSnackbar(true);
      showSnackbarInfo(t(errorKey));
    } else if (500 === statusCode) {
      console.debug("Internal server error");
      toggleSnackbar(true);
      showSnackbarInfo(t('validation:connection_error'));
    } else {
      console.debug("Unknown error");
      toggleSnackbar(true);
      showSnackbarInfo(t('validation:unknown_error'));
    }
    setResettingPass(false);
    setFormState(formState => ({
      ...formState,
      serverErrors: validationResult
    }));
  };

  const getError = field => {
    if (formState.touched[field]) {
      return (
        <React.Fragment>
          {
            formState.validationErrors[field] &&
            <p style={{margin: 0}}>{formState.validationErrors[field][0]}</p>
          }
          {
            formState.serverErrors[field] &&
            <p style={{margin: 0}}>
              {
                i18n.exists("validation:" + validi18nKey(formState.serverErrors[field][0])) ?
                  t("validation:" + validi18nKey(formState.serverErrors[field][0]))
                  :
                  t("validation:not_valid_value")
              }
            </p>
          }
        </React.Fragment>
      )
    }
    return null;
  }

  const handleClickShowPassword = index => {
    switch (index) {
      case 0:
        setShowPassword0(!showPassword0);
        break;
      case 1:
        setShowPassword1(!showPassword1);
        break;
      case 2:
        setShowPassword2(!showPassword2);
        break;
      default:
        break
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeSnackbar = () => {
    toggleSnackbar(false);
  }

  const hasError = field => {
    return !!(formState.touched[field] && (formState.validationErrors[field] || formState.serverErrors[field]));
  }

  const renderForm = () => {
    return (
      <form
        className={classes.form}
        onSubmit={handlePasswordReset}
      >
        <Typography
          className={classes.title}
          variant="h4"
        >
          {t('reset_password_text')}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          style={{marginBottom: theme.spacing(3)}}
        >
          {t('reset_password_subtitle')}
        </Typography>
        <TextField
          className={classes.textField}
          error={hasError('currentPassword')}
          fullWidth
          FormHelperTextProps={{
            component: "div"
          }}
          helperText={
            hasError('currentPassword') ? getError('currentPassword') : null
          }
          label={t('current_password')}
          name="currentPassword"
          onChange={handleChange}
          type={showPassword0 ? 'text' : 'password'}
          value={formState.values.currentPassword || ''}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword(0)}
                  onMouseDown={handleMouseDownPassword}
                  >
                  {showPassword0 ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            ),
            labelWidth: 70
          }}
        />
        <TextField
          className={classes.textField}
          error={hasError('newPassword1')}
          fullWidth
          helperText={
            hasError('newPassword1') ? getError('newPassword1') : null
          }
          label={t('new_password')}
          name="newPassword1"
          onChange={handleChange}
          FormHelperTextProps={{
            component: "div"
          }}
          type={showPassword1 ? 'text' : 'password'}
          value={formState.values.newPassword1 || ''}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword(1)}
                  onMouseDown={handleMouseDownPassword}
                  >
                  {showPassword1 ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            ),
            labelWidth: 70
          }}
        />
        <TextField
          className={classes.textField}
          error={hasError('newPassword2')}
          fullWidth
          helperText={
            hasError('newPassword2') ? getError('newPassword2') : null
          }
          label={t('repeat_new_password')}
          name="newPassword2"
          onChange={handleChange}
          FormHelperTextProps={{
            component: "div"
          }}
          type={showPassword2 ? 'text' : 'password'}
          value={formState.values.newPassword2 || ''}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword(2)}
                  onMouseDown={handleMouseDownPassword}
                  >
                  {showPassword2 ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            ),
            labelWidth: 70
          }}
        />
        <Button
          className={classes.mainButton}
          color="primary"
          disabled={!formState.isValid || resettingPass}
          startIcon={resettingPass ? <CircularProgress size={20}/> : <span/>}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t('change_password')}
        </Button>
      </form>
    )
  }

  const renderSuccess = () => {
    return (
      <Redirect
        to={{pathname: "/contratos", state: {from: props.location}}}
      />
    )
  }

  return (
    <Fade in={true}>
      <Container className={classes.root} maxWidth="md">
        <div className={classes.content}>
          <Card className={classes.contentBody} variant="outlined">
            {
              passwordChanged ?
                renderSuccess()
                :
                renderForm()
            }
          </Card>
        </div>
        <Snackbar open={snackbarOpen} autoHideDuration={6000}
                  onClose={closeSnackbar}>
          <Alert
            severity={'error'}
            onClose={closeSnackbar}>
            {snackbarInfo}
          </Alert>
        </Snackbar>
      </Container>
    </Fade>
  );
};

export default ResetPassword;
