import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { UserService } from "../../service";
import validate from 'validate.js';
import { history, i18n, theme } from "../../config";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ArrowBack } from "@material-ui/icons";
import { translateToHTML, validi18nKey } from "../../utils";

const schema = {
  nif: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 255
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: "^" + i18n.t("validation:field_required")
    },
    length: {
      maximum: 255
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    flexDirection: "column",
  },
  form: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 65,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  mainButton: {
    margin: theme.spacing(2, 0)
  }
}));

const RecoverPassword = props => {

  const classes = useStyles();
  const {t} = useTranslation(['common', 'validation']);
  const userService = new UserService();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    validationErrors: {},
    serverErrors: {}
  });

  const [correctForm, setCorrectForm] = useState(false);
  const [snackbarOpen, toggleSnackbar] = useState(false);
  const [snackbarInfo, showSnackbarInfo] = useState("");
  const [recoveringPass, setResettingPass] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    let validationErrors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: !validationErrors,
      validationErrors: validationErrors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handlePasswordReset = async event => {
    event.preventDefault();

    setResettingPass(true);
    let [statusCode, serverErrors] = await userService.recoverPassword(
      formState.values.nif, formState.values.email
    );

    if (200 <= statusCode && statusCode < 300) {
      setUserEmail(formState.values.email);
      setCorrectForm(true);
    } else if (400 <= statusCode && statusCode < 500) {
      let errorKey = 'validation:incorrect_form';
      if ('errors' in serverErrors)
        serverErrors = serverErrors.errors;
      toggleSnackbar(true);
      showSnackbarInfo(t(errorKey));
    } else if (500 === statusCode) {
      console.debug("Internal server error");
      toggleSnackbar(true);
      showSnackbarInfo(t('validation:connection_error'));
    } else {
      console.debug("Unknown error");
      toggleSnackbar(true);
      showSnackbarInfo(t('validation:unknown_error'));
    }
    setResettingPass(false);
    setFormState(formState => ({
      ...formState, serverErrors
    }));
  };

  const getError = field => {
    if (formState.touched[field]) {
      return (
        <React.Fragment>
          {
            formState.validationErrors[field] &&
            <p style={{margin: 0}}>{formState.validationErrors[field][0]}</p>
          }
          {
            formState.serverErrors[field] &&
            <p style={{margin: 0}}>
              {
                i18n.exists("validation:" + validi18nKey(formState.serverErrors[field][0])) ?
                  t("validation:" + validi18nKey(formState.serverErrors[field][0]))
                  :
                  t("validation:not_valid_value")
              }
            </p>
          }
        </React.Fragment>
      )
    }
    return null;
  }

  const closeSnackbar = () => {
    toggleSnackbar(false);
  }

  const hasError = field => {
    return !!(formState.touched[field] && (formState.validationErrors[field] || formState.serverErrors[field]));
  }

  const renderForm = () => {
    return (
      <form
        className={classes.form}
        onSubmit={handlePasswordReset}
      >
        <Typography
          className={classes.title}
          variant="h4"
        >
          {t('recover_password_text')}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          style={{marginBottom: theme.spacing(3)}}
        >
          {t('recover_password_subtitle')}
        </Typography>
        <TextField
          className={classes.textField}
          error={hasError('nif')}
          fullWidth
          FormHelperTextProps={{
            component: "div"
          }}
          helperText={
            hasError('nif') ? getError('nif') : null
          }
          label={t('nif')}
          name="nif"
          onChange={handleChange}
          type="text"
          value={formState.values.nif || ''}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError('email')}
          fullWidth
          helperText={
            hasError('email') ? getError('email') : null
          }
          label={t('email')}
          name="email"
          onChange={handleChange}
          FormHelperTextProps={{
            component: "div"
          }}
          type="text"
          value={formState.values.email || ''}
          variant="outlined"
        />
        <Button
          className={classes.mainButton}
          color="primary"
          disabled={!formState.isValid || recoveringPass}
          startIcon={recoveringPass ? <CircularProgress size={20}/> : <span/>}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {t('send')}
        </Button>
      </form>
    )
  }

  const renderSuccess = () => {
    return (
      <div style={{ padding: theme.spacing(3)}}>
        <Typography
          style={{marginTop: theme.spacing(2)}}
          variant="h4"
        >
          {t('validation:recover_success')}
        </Typography>
        <Typography variant="subtitle1" style={{marginTop: theme.spacing(2)}}>
          {translateToHTML(t, 'validation:recover_success_explanation', {email: userEmail})}
        </Typography>
        <Button
          className={classes.signUpButton}
          color="primary"
          style={{marginTop: theme.spacing(2)}}
          onClick={() => history.push('/')}
          startIcon={<ArrowBack/>}
          variant="contained"
        >
          {t('return_home')}
        </Button>
      </div>
    )
  }

  return (
    <Fade in={true}>
      <Container className={classes.root} maxWidth="md">
        <div className={classes.content}>
          <Card className={classes.contentBody} variant="outlined">
            {
              correctForm ?
                renderSuccess()
                :
                renderForm()
            }
          </Card>
        </div>
        <Snackbar open={snackbarOpen} autoHideDuration={6000}
                  onClose={closeSnackbar}>
          <Alert
            severity={'error'}
            onClose={closeSnackbar}>
            {snackbarInfo}
          </Alert>
        </Snackbar>
      </Container>
    </Fade>
  );
};

export default RecoverPassword;
