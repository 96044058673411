import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import UploadButton from './UploadButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export const validate = (value, options) => {
  const minUploads = options.min || 0;
  const maxUploads = options.max || 0;
  const isIterable = Symbol.iterator in Object(value);
  const minMessage = options.minMessage || 'minimum files is';
  const maxMessage = options.maxMessage || 'maximum files is';

  if (minUploads > 0) {
    if (!isIterable) {
      return `${minMessage} ${minUploads}`;
    } else {
      const notNulls = value.filter((x) => x !== null && x !== undefined);
      console.log(notNulls);
      if (notNulls.length < minUploads) {
        return `${minMessage} ${minUploads}`;
      }
      if (maxUploads > 0 && notNulls.length > maxUploads) {
        return `${maxMessage} ${maxUploads}`;
      }
    }
  }
};

class MultiUploadButton extends React.Component {
  constructor(props) {
    super(props);
    const uploadsArr =  props.value || [...Array(props.min)];
    this.state = {
      uploadsArr
    }
  }

  handleOnChange = (position, value) => {
    const uploadsArr = [...this.state.uploadsArr];
    uploadsArr[position] = value;
    this.setState({uploadsArr});
    // Trim uploads array to discard empty (undefined) entries.
    const trimmedUploadArr = uploadsArr.filter((value) => {
      return !_.isEmpty(value);
    });
    this.props.onChange(trimmedUploadArr);
  }

  addAnotherUploadArr = (e) => {
    e.preventDefault();
    const uploadsArr = [...this.state.uploadsArr];
    uploadsArr.push(undefined);
    this.setState({uploadsArr});
  }

  removeUpload = (e) => {
    e.preventDefault();
    const uploadsArr = [...this.state.uploadsArr];
    const value = uploadsArr.pop();
    this.setState({uploadsArr});
    if (value) {
      this.props.onChange(uploadsArr);
    }
  }

  render() {
    const uploadButtonsArr = this.state.uploadsArr.map((k, i) => {
      const label = this.state.uploadsArr.length > 1 ? `${this.props.label} - ${i + 1}` : this.props.label;
      const position = i;
      return (
        <div key={i} style={{display: 'flex'}}>
          <UploadButton label={label} errorText={i < this.props.min && this.props.errorText} position={position} onChange={(value) => this.handleOnChange(i, value)} value={k} />
          {i + 1 > this.props.min && i + 1 ===  this.state.uploadsArr.length &&
            <Button onClick={this.removeUpload} style={{fontSize: 12}} startIcon={<RemoveIcon/>}>{this.props.removeLabel}</Button>
          }
        </div>
      )
    });
    const allowMoreUplodads = this.state.uploadsArr.length < this.props.max;
    return (
      <div>
        {uploadButtonsArr}
        {allowMoreUplodads &&
          <Button onClick={this.addAnotherUploadArr} startIcon={<AddIcon/>} style={{marginLeft: 5, fontSize: 12}}>{this.props.anotherLabel}</Button>
        }
        {this.props.hint &&
          <div className="helper-text">{this.props.hint}</div>
        }
      </div>
    )
  }
}

export default MultiUploadButton;


MultiUploadButton.defaultProps = {
  min: 1,
  max: 5,
  value: [],
  anotherLabel: 'Add',
  removeLabel: 'Remove'
};
