import React from 'react';
import TextFieldReadOnly from '../../../components/TextFieldReadOnly';
import * as PropTypes from "prop-types";
import { theme } from '../../../config';
import { InfoContainer, LinkRouter } from "../../../components";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import { meterDetailEnabled, safeGet, b64toBlob } from "../../../utils";
import _ from 'lodash';
import { CloudDownload, Launch } from "@material-ui/icons";
import { ContractService } from "../../../service";
import saveAs from "file-saver";
import ConsumptionCertificateDialog from "./ConsumptionCertificateDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Color from "color";

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
  },
  contrastPrimaryText: {
    color: theme.palette.primary.contrastText
  },
  flexCenter: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoCards: {
    padding: theme.spacing(4)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  downloadSection: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  meterSummary: {
    backgroundColor: Color(theme.palette.primary.main).fade(0.9).rgb().string(),
    width: "100%",
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main
  }
});

class ContractDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloadCertificateDialogOpen: false,
      downloadingConsumptionCertificate: false,
    };
    this.contractService = new ContractService();
  }

  renderMeterSummary = () => {
    const { classes, contract, t } = this.props;
    if ( safeGet(contract, "meter", false) ) {
      const meterDetailsEnabled = meterDetailEnabled(contract.meter);
      return (
        <Card elevation={0} className={classes.meterSummary}>
          <Grid container direction="row"
                className={classes.flexCenter}
                style={{
                  padding: theme.spacing(1),
                  textAlign: !meterDetailsEnabled && 'center'
                }}>
            <Grid item xs>
              <Typography variant="h6" component="h6" style={{padding: theme.spacing(1)}}>
                {t("measuring_device")}
              </Typography>
              <div style={{padding: theme.spacing(1)}}>
                <Typography variant="subtitle2" component="h6">
                  {t("id_number")}
                </Typography>
                <Typography variant="subtitle2" component="h5">
                  { safeGet(contract, "meter.serial", "N/A") }
                </Typography>
              </div>
            </Grid>
            {
              meterDetailsEnabled && <Grid item xs>
                <CardActions className={classes.button}>
                  <LinkRouter to={`/contratos/${ safeGet(contract, "name", '')}/contador`}>
                    <Button
                      variant="contained"
                      color="inherit"
                      style={{backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText}}
                      startIcon={<Launch color="inherit"/>}
                    >
                      <Typography variant="button">
                        {t('query', {what: t('meter')})}
                      </Typography>
                    </Button>
                  </LinkRouter>
                </CardActions>
              </Grid>
            }
          </Grid>
        </Card>
      )
    }
  };

  renderPowerTable() {
    let powerPeriods = safeGet(this.props.contract, 'contracted_power_period', []);
    // eslint-disable-next-line
    for (const idx of _.range(powerPeriods.length, 6)) {
      powerPeriods.push(undefined);
    }
    return (
      <TableContainer component={Paper} elevation={0} variant="outlined">
        <Table size="small" elevation={0}>
          <TableHead>
            <TableRow>
              <TableCell>{this.props.t('common:contracted_power_by_period')}</TableCell>
              <TableCell>{"P1"}</TableCell>
              <TableCell>{"P2"}</TableCell>
              <TableCell>{"P3"}</TableCell>
              <TableCell>{"P4"}</TableCell>
              <TableCell>{"P5"}</TableCell>
              <TableCell>{"P6"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{`${this.props.t('power')} (kW)`}</TableCell>
              {
                powerPeriods.map(
                  (contracted_power, idx) => <TableCell key={idx}>
                    {safeGet(contracted_power, 'power', 0)}
                  </TableCell>
                )
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  handleDownloadCertificateRequest(startDate, endDate) {
    this.setState({
      downloadCertificateDialogOpen: false,
    });
    if (startDate && endDate) {
      this.setState({
        downloadingConsumptionCertificate: true
      });
      this.contractService.downloadConsumptionCertificate(
        this.props.contract.name, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),
      ).then(
        response => {
          this.setState({downloadingConsumptionCertificate: false});
          // AXIOS has blocked access to content-disposition header
          // const filename = response.headers["content-disposition"].split("=");
          // So we have to set the file name by ourselves
          const consumption = this.props.t('consumption').toUpperCase();
          startDate = startDate.format('DD-MM-YYYY');
          endDate = endDate.format('DD-MM-YYYY');
          let blob = b64toBlob(response.data, "application/pdf");
          const filename = `${consumption}_${this.props.contract.name}_${startDate}_${endDate}.pdf`;
          saveAs(blob, filename);
        }
      ).catch(
        error => {
          this.setState({downloadingConsumptionCertificate: false});
        }
      );
    }
  }

  render() {
    const {contract, classes, t} = this.props;
    const { downloadCertificateDialogOpen, downloadingConsumptionCertificate } = this.state;
    return (
      <InfoContainer
        title={safeGet(contract, "cups.full_address", "N/A")}
        subtitle={`${t('cups')} ${safeGet(contract, "cups.name")}`}
      >
        <Divider/>
        <CardContent className={classes.infoCards}>
          <Grid container spacing={3}>
            <Grid item container sm>
              <Grid item xs={12}>
                <TextFieldReadOnly label={t('cups')}>
                  { safeGet(contract, "cups.name", "N/A") }
                </TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} className={classes.marginTop}>
                <TextFieldReadOnly label={t("tariff")}>
                  { safeGet(contract, "tariff.name", "N/A") }
                </TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} className={classes.marginTop}>
                <TextFieldReadOnly label={t("comer")}>
                  { safeGet(contract, "comer.name", "N/A") }</TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} className={classes.marginTop}>
                <TextFieldReadOnly label={t("tg")}>
                  { safeGet(contract, "tg", "N/A") }
                </TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} className={classes.marginTop}>
                <TextFieldReadOnly label={t("state")}>
                  { safeGet(contract, "state", "N/A") }
                </TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} className={classes.marginTop}>
                <TextFieldReadOnly label={t("fact_power_mode")}>
                  { safeGet(contract, "facturacio_potencia", "N/A") }
                </TextFieldReadOnly>
              </Grid>
            </Grid>
            <Grid item container sm>
              <Grid item xs={12}>
                { this.renderMeterSummary() }
              </Grid>
              <Grid item xs={12}>
                { this.renderPowerTable() }
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes.marginTop}/>
          <Grid container className={classes.marginTop}>
            <Grid item xs={12} className={classes.downloadSection}>
              <Button
                variant="outlined"
                disabled={downloadingConsumptionCertificate || downloadCertificateDialogOpen}
                startIcon={
                  downloadingConsumptionCertificate ? <CircularProgress size={20}/> : <CloudDownload color="primary"/>
                }
                onClick={() => this.setState({
                  downloadCertificateDialogOpen: true
                })}
              >
                <Typography
                  color="primary"
                  variant="button"
                >
                  {t('do', {what: t('download'), over: t('consumption_certificate')})}
                </Typography>
                <ConsumptionCertificateDialog
                  open={downloadCertificateDialogOpen}
                  onClose={
                    (dateStart, dateEnd) => this.handleDownloadCertificateRequest(dateStart, dateEnd)
                  }/>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </InfoContainer>
    )
  }
}

ContractDetail.propTypes = {
  contract: PropTypes.object
};

export default compose(
  withStyles(styles),
  withTranslation(['common', 'validation']),
)(ContractDetail);
