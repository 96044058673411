import React from "react";
import {CardContent, Divider, Grid} from "@material-ui/core";
import TextFieldReadOnly from "../../../components/TextFieldReadOnly";
import {safeGet} from "../../../utils";
import {InfoContainer} from "../../../components";
import {makeStyles} from "@material-ui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  infoCards: {
    padding: theme.spacing(4)
  }
}));

export const OwnerDetails = props => {
  const { contract } = props;
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <InfoContainer
      title={t('owner')} subtitle={t('data_related_to')}
    >
      <Divider/>
      <CardContent className={classes.infoCards}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextFieldReadOnly label={t('complete_name')}>
              {safeGet(contract, "owner_partner.name", "N/A")}
            </TextFieldReadOnly>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldReadOnly label={t('supply_address')}>
              {safeGet(contract, "cups.full_address", "N/A")}
            </TextFieldReadOnly>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldReadOnly label={t('nif')}>
              {safeGet(contract, "owner_partner.vat", "N/A")}
            </TextFieldReadOnly>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldReadOnly label={t('mobile')}>
              {safeGet(contract, "owner_partner.mobile", "N/A")}
            </TextFieldReadOnly>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldReadOnly label={t('email')}>
              {safeGet(contract, "owner_partner.email", "N/A")}
            </TextFieldReadOnly>
          </Grid>
        </Grid>
      </CardContent>
    </InfoContainer>
  );
}
