import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Paper } from "@material-ui/core";
import { InfoContainer } from "../../components";
import Fade from "@material-ui/core/Fade";
import PersonIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import EcoIcon from '@material-ui/icons/Eco';
import ErrorIcon from '@material-ui/icons/Error';
import EvStationIcon from '@material-ui/icons/EvStation';
import Typography from '@material-ui/core/Typography';
import { history } from '../../config';
import { i18n } from "../../config";
import Settings from "../../settings.yml";
import {
  fetchCRMCaseSections
} from "../../actions";

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: '20px',
    verticalAlign: 'center',
  },
  '@keyframes appButtonSelected': {
    from: {backgroundColor: theme.palette.primary.main},
    to: {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: `0 0 5px ${theme.palette.primary.main}`
    }
  },
  appButton: {
    minHeight: '20vh',
    minWidth: '20vh', 
    textAlign: 'center', 
    padding: '10px', 
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
    '&:hover': {
      animation: '$appButtonSelected 0.5s forwards',
      cursor: 'pointer',
    }
  }
});

const AppButton = ({name, icon, path, theme, state}) => {
  return(
    <Paper className={theme} onClick={() => {history.push(path, state)}}>
      <div>
        {icon}
      </div>
      <div>
        <Typography variant="h5" style={{userSelect: 'none', fontWeight: '450'}}>
          {name}
        </Typography>
      </div>
    </Paper>
  )
};

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount() {
    if(!("RECLAMACIO" in this.props.sections)) {
      this.props.fetchCRMCaseSections('RECLAMACIO');
    }

    if(!("EVEHICLE" in this.props.sections)) {
      this.props.fetchCRMCaseSections('EVEHICLE');
    }
  }

  getCRMViews = () => {
    const { classes } = this.props;
    let viewsArray = [
      {route: 'genericas', section: "SOL_CRM", icon: <MailIcon style={{ fontSize: 80 }}/>},
      {route: 'autoconsumo', section: "AUTOCONS", icon: <EcoIcon style={{ fontSize: 80 }}/>},
    ];

    if(_.get(this.props, 'sections.RECLAMACIO.data', false) && 
        this.props.sections.RECLAMACIO.data.length > 0){
      viewsArray.push({route: 'reclamacio', section: "RECLAMACIO", icon: <ErrorIcon style={{ fontSize: 80 }}/>});
    }

    if(_.get(this.props, 'sections.EVEHICLE.data', false) && 
        this.props.sections.EVEHICLE.data.length > 0){
      viewsArray.push({route: 'evehicle', section: "EVEHICLE", icon: <EvStationIcon style={{ fontSize: 80 }}/>});
    }

    if(Settings.crm_enabled){
      return (viewsArray.map((element, idx) => 
      <Grid item key={idx}>
        <AppButton 
          name={i18n.t(`common:crm_distri_${element.route}`)}
          path={`/solicitudes/${element.route}`}
          icon={element.icon}
          theme={classes.appButton}
        />
      </Grid>
      ))
    } else {
      return [];
    }
  }


  render(){
    const { classes } = this.props;
    return (
      <Fade in={true}>
        <InfoContainer
          maxWidth={"lg"}
          className={classes.container}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <AppButton 
                name={i18n.t("common:contract_plural")}
                path="/contratos"
                icon={<PersonIcon style={{ fontSize: 80 }}/>}
                theme={classes.appButton}
              />
            </Grid>
            {this.getCRMViews()}
          </Grid>
        </InfoContainer>
      </Fade>
    )
  }
}

const mapDispatchToProps = {
  fetchCRMCaseSections
};

const mapStateToProps = (state) => {
  return {
    sections: _.get(state, 'CRMCases.sections', {}),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'validation']),
  withStyles(styles),
  withRouter
)(Home);