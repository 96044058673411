import apiWrapper from "./AxiosRequestsClass";
import _ from "lodash";
import { insist, parseResponse } from "./utils";

export default class ContractService {
  cancelRequests = () => {
    return apiWrapper.cancelRequests();
  };

  constructor(config) {
    if (config) {
      this.config = config;
    } else {
      this.config = {
        timeout: 300000,
      };
    }
  }

  getContract = (name) => {
    return apiWrapper.get(`/contracts/${name}/`, this.config);
  };

  getContractList = (offset, limit) => {
    return apiWrapper.get(
      `/contracts/?offset=${offset}&limit=${limit}`,
      this.config,
      "v2"
    );
  };

  getCRMContractList = (offset, limit) => {
    return apiWrapper.get(
      `/crm/contracts/?offset=${offset}&limit=${limit}`,
      this.config,
      "v2"
    );
  };

  getContractFullList = (offset, limit) => {
    //this call is deprecated
    return apiWrapper.get(
      `/contracts/?offset=${offset}&limit=${limit}`,
      this.config
    );
  };

  requestInstantMeasureTask = (contractName, meterName) => {
    return apiWrapper
      .post(
        `/contracts/${contractName}/meterinstantdata/`,
        {
          params: {
            meter: meterName,
          },
        },
        this.config
      )
      .then((response) => parseResponse(response))
      .catch((error) => parseResponse(error));
  };

  fetchInstantMeasureTask = (contractName, task_id, meterName) => {
    const config = {
      ...this.config,
      params: {
        id: task_id,
        meter: meterName,
      },
    };
    return apiWrapper
      .get(`/contracts/${contractName}/meterinstantdata/`, config)
      .then((response) => {
        let [statusCode, data] = parseResponse(response);
        data = _.head(_.values(_.get(data, "status.result", {}))) || {};
        if (data && !_.isEmpty(data)) {
          return Promise.resolve([statusCode, data]);
        } else {
          return Promise.reject({
            // We reject the result with an Axios-like promise
            response: {
              status: statusCode,
              data,
            },
          });
        }
      });
  };

  meterInstantData = async (contractName, meterName) => {
    // We request an instant measure and we get a task ID to work asyncronously
    // with the API
    let [statusCode, data] = await this.requestInstantMeasureTask(
      contractName,
      meterName
    );
    const task_id = data.id || null;
    if (statusCode === 200 && task_id) {
      // If we have the task_id, we'll request the result with a limit of 120
      // times with 1 second of delay
      const retries = 120;
      const delay = 1000;
      [statusCode, data] = await insist(
        this.fetchInstantMeasureTask,
        [contractName, task_id, meterName],
        retries,
        delay
      ).catch((response) => parseResponse(response));
    }
    return [statusCode, data];
  };

  reconnectICP = (contractName) => {
    return apiWrapper
      .post(`/contracts/${contractName}/icpreconnection/`, {}, this.config)
      .then((response) => parseResponse(response))
      .catch((error) => parseResponse(error));
  };

  getChartData = (contractName, startDate, endDate, chartType) => {
    const config = {
      ...this.config,
      params: {
        start: startDate,
        end: endDate,
      },
    };
    return apiWrapper
      .get(`/contracts/${contractName}/cch/chart/${chartType}/`, config)
      .then((result) => _.get(result, "data", []))
      .catch((error) => []);
  };

  downloadCCH = (
    contractName,
    startDate,
    endDate,
    fileFormat = "xlsx",
    timeFormat
  ) => {
    return apiWrapper.get(`/contracts/${contractName}/cch/download/`, {
      ...this.config,
      responseType: "blob",
      params: {
        start: startDate,
        end: endDate,
        fileFormat: fileFormat,
        timeFormat: timeFormat,
      },
    });
  };

  downloadConsumptionCertificate = (contractName, startDate, endDate) => {
    const config = {
      ...this.config,
      responseType: "text",
      params: {
        start: startDate,
        end: endDate,
      },
    };
    return apiWrapper.get(
      `/contracts/${contractName}/consumption-certificate/download/`,
      config
    );
  };

  getMaximeterChart = (contractName, startDate, endDate) => {
    return apiWrapper
      .get(`/contracts/${contractName}/maximeterchart/`, {
        ...this.config,
        params: {
          start: startDate,
          end: endDate,
        },
      })
      .then((result) => _.get(result, "data", []))
      .catch((error) => []);
  };

  fetchInstantMeterMeasures = (contractName, meterName, type = null) => {
    return apiWrapper
      .get(`/contracts/${contractName}/scheduledmeasures/`, {
        ...this.config,
        params: {
          meter: meterName,
          type,
        },
      })
      .then((result) => _.get(result, "data", []))
      .catch((error) => []);
  };

  scheduleInstantMeasure = (contractName, meter_name, date) => {
    const requestOptions = {
      meter_name,
      date,
    };
    return apiWrapper
      .post(
        `/contracts/${contractName}/scheduledmeasures/`,
        requestOptions,
        this.config
      )
      .then((response) => true)
      .catch((error) => false);
  };
}
