import React from 'react';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import 'moment/locale/es';
import DialogActions from "@material-ui/core/DialogActions";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import {Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { theme } from '../../../config';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";

class ScheduleMeasureDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState() {
    return {
      selectedDate: moment(),
      scheduling: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open === false && this.props.open === true) {
      this.setState(this.initialState());
    }
  }

  handleClose = (doRequest=false) => {
    let { selectedDate } = this.state;
    if (doRequest) {
      this.setState({ scheduling: true });
    }
    else {
      selectedDate = false
    }

    this.props.onClose(selectedDate);
  };

  handleDateChange = selectedDate => {
    this.setState({selectedDate})
  };

  render() {
    const { t, open } = this.props;
    const { selectedDate, scheduling } = this.state;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog open={open} onClose={() => this.handleClose(false)} maxWidth={"md"}>
          <Grid container justify="space-between">
            <DialogTitle>{t("common:schedule_measure")}</DialogTitle>
            <IconButton
              style={{
                marginRight: theme.spacing(1)
              }}
              onClick={() => this.handleClose(false)}
            >
              <Close/>
            </IconButton>
          </Grid>
          <DialogContent>
            <DialogContentText>
              {t('common:schedule_dialog_info')}
            </DialogContentText>
            <Grid container spacing={2} justify="space-around" style={{marginTop: theme.spacing(3)}}>
              <Grid item>
                <KeyboardDatePicker
                  autoOk
                  disablePast={true}
                  variant="inline"
                  inputVariant="outlined"
                  label={t('common:date')}
                  format="DD/MM/YYYY"
                  value={selectedDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => this.handleDateChange(date)}
                />
              </Grid>
              <Grid item>
                <KeyboardTimePicker
                  autoOk
                  disablePast={true}
                  ampm={false}
                  variant="inline"
                  inputVariant="outlined"
                  label={t('common:hour')}
                  format="HH:mm"
                  value={selectedDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => this.handleDateChange(date)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleClose(true)}
              variant="contained" disableElevation color="primary"
              disabled={!selectedDate || scheduling}
              startIcon={
                scheduling ? <CircularProgress size={20}/> : null
              }
            >
              {t('common:schedule_measure')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    )
  }
}

export default withTranslation(['common', 'validation'])(ScheduleMeasureDialog);
