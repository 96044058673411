import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';


class UploadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.value,
      position: props.position || 0
    };
  }

  handleOnFileLoad = (e) => {
    let reader = new FileReader();
    const targetFile = e.target.files[0];
    reader.onloadend = () => {
      const file = {
        name: targetFile.name,
        content: reader.result
      };
      this.setState({file});
      if (this.props.onChange) {
       this.props.onChange(file);
      }
    }
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }

  };

  handleClearFile = () => {
    this.setState({
      file: null
    });
    if (this.props.onChange) {
      this.props.onChange(null);
    }
  };

  render() {

    const {file, position} = this.state;
    const {errorText} = this.props;

    return (
      <div style={{...this.props.style, ...{display: 'flex', flexWrap: 'wrap'}}}>
        <input type="file" style={{display: "none"}} id={`upload-attachment-${position}`} onChange={this.handleOnFileLoad}/>
        <label htmlFor={`upload-attachment-${position}`}>
          <Button startIcon={file?<AttachmentIcon/>:this.props.icon} component="span">
            {this.props.label}
          </Button>
        </label>
        {file &&
          <div style={{display: 'flex'}}>
            <div style={{padding: '8px'}}>{file.name}</div>
            <Button startIcon={<ClearIcon/>} onClick={this.handleClearFile}/>
          </div>
          }
            {errorText &&
            <div style={{padding: '8px', color: "red.500"}}>{errorText}</div>
            }
      </div>
    );
  }
}

export default UploadButton;

UploadButton.defaultProps = {
  file: null,
  label: 'Upload',
  icon: <PublishIcon/>,
};
