import React from 'react';
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paper, Grid } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import saveAs from 'file-saver';
import { Alert } from '@material-ui/lab';

class RequirementDoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filled: false,
      file: props.value,
      index: props.index
    };
  }

  handleOnFileLoad = (e) => {
    let reader = new FileReader();
    const targetFile = e.target.files[0];  
    reader.onloadend = () => {
      const file = {
        name: targetFile.name,
        content: reader.result,
      };
      this.setState({file: file, filled: true});
      if (this.props.handleUploaded) {
        this.props.handleUploaded(file, this.props.index, this.props.required === 'must_fill');
      }
    }
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }
  };

  handleClearFile = () => {
    this.setState({
      file: null,
      filled: false
    });
    if (this.props.handleUploaded) {
      this.props.handleUploaded(null, this.props.index, this.props.required === 'must_fill');
    }
  };

  handleDownload = () => {
    saveAs(this.state.file.content, this.state.file.name);
  };

  render() {

    const {downloadFunction, downloadLabel, required, t} = this.props;

    return (
      <Paper style={{...this.props.style, ...{textAlign:'center', minHeight: '90px'}}} onClick={() => {}}>
        <Grid container spacing={1} alignItems="center"  style={{minHeight: '90px'}}>
          <Grid item xs={3}>
            <DescriptionIcon style={{ fontSize: 52 }}/>
          </Grid>
          <Grid container item xs={9}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom style={{fontWeight: '450'}}>
                {this.props.fileLabel}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                size="small"
                onClick={() => {downloadFunction(this.props.attachmentId)}}
              >
                {downloadLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {required !== 'information' &&
          <Grid container spacing={1} style={{width: 'auto'}} alignItems="center">
            <Grid item xs={this.state.filled === true ? 9 : 12}>
              {!this.state.filled &&
                <input type="file" style={{display: "none"}} id={`upload-attachment-${this.props.attachmentId}`} onChange={this.handleOnFileLoad}/>
              }
              <label htmlFor={`upload-attachment-${this.props.attachmentId}`}>
                <Tooltip 
                  title={this.state.filled ? t("common:download", {what: t('file').toLowerCase()}) : t("common:upload", {what: t('file').toLowerCase()}) } 
                  aria-label="upload-file"
                >
                  <Chip
                    label={this.state.filled ? this.state.file.name  :  t("common:upload", {what: t('file').toLowerCase()})}
                    clickable
                    onClick={this.state.filled ? this.handleDownload : () => {}}
                    onDelete={this.state.filled ? this.handleDownload : () => {}}
                    color={this.state.filled ? "primary" : "default"}
                    style={{maxWidth: 200}}
                    deleteIcon={this.state.filled ? <GetAppIcon /> : <PublishIcon />}
                  />
                </Tooltip>
              </label>
            </Grid>
            {this.state.filled && 
              <Grid item>
                <Tooltip title="Remove file" aria-label="delete">
                  <IconButton aria-label="delete" onClick={this.handleClearFile}>
                    <DeleteIcon style={{color: '#DC004E'}} fontSize="small"/>
                  </IconButton>
                </Tooltip>
              </Grid>
            }
            {required === 'must_fill' && !this.state.filled &&
              <Grid item xs={12} style={{paddingBottom: 0}}>
                <Alert severity="error">
                  {t("common:must_fill_in")}
                </Alert>
              </Grid>
            }   
        </Grid>
        }
      </Paper>
    );
  }
}

export default compose(
  withTranslation(['common', 'validation']),
)(RequirementDoc);

RequirementDoc.defaultProps = {
  file: null,
  fileLabel: 'Document',
  downloadLabel: 'Download',
  required: false,
  attachmentId: null,
  index: 0,
};
