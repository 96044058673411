import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

// UserBreadcrumb.jsx
const CRMDetailBreadcrumb = ({ customId }) => <span>{customId}</span>;

// find the user in the store with the `id` from the route
const mapStateToProps = (state, props) =>
  ({
    customId: _.get(state, 'CRMCases.case.referencia_externa', props.match.params.id)
  });

export default connect(mapStateToProps)(CRMDetailBreadcrumb);