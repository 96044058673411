import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Provider from './components/Provider';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CssBaseline from "@material-ui/core/CssBaseline";
import { I18nextProvider } from "react-i18next";

import { ResponsiveContextProvider } from './contexts';
import Routes from "./Routes";
import { i18n, theme, store, history } from './config';
import { disableLogs } from "./utils";
import { ErrorBoundary } from './components';
import * as Sentry from '@sentry/react';
import Settings from "./settings.yml";

(disableLogs)();

if (Settings.sentryDsn) {
  const sentryConf = {
    dsn: Settings.sentryDsn
  };
  Sentry.init(sentryConf);
}

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store} history={history}>
          <I18nextProvider i18n={i18n}>
            <ResponsiveContextProvider>
              <CssBaseline/>
              <Routes/>
            </ResponsiveContextProvider>
          </I18nextProvider>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
