import React from 'react';
import { AppBar, Toolbar, withStyles } from '@material-ui/core';
import * as PropTypes from "prop-types";
import { LinkRouter } from "../../../../components";
import clsx from "clsx";
import { BarLogo } from "../../../../config";

const styles = theme => ({
  mainAppbar: {
    margin: "0 auto",
    padding: 0,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2)
  },
});

function Topbar(props) {
  const {className, classes, ...rest} = props;
  return (
    <React.Fragment>
      <AppBar
        {...rest}
        className={clsx(className, classes.mainAppbar)}
        position="relative"
        elevation={0}
        style={{flexGrow: 1}}
      >
        <Toolbar>
          <LinkRouter to="/">
            <BarLogo />
          </LinkRouter>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(Topbar);
