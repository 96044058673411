import React from 'react';
import { Redirect } from "react-router-dom";

const Logout = props => {
  return <Redirect to={{
    pathname: '/acceso',
    state: { from: props.location }
  }} />
};

export default Logout;