import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import { withTranslation } from "react-i18next";
import { sendNewCRMCaseMessage, fetchCRMCaseDetail } from '../../../actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import MultiUploadButton from "../components/MultiUploadButton";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { Avatar, Grid, Paper } from "@material-ui/core";
import MD5 from "crypto-js/md5";

const styles = (theme) => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  table: {
    margin: "5px 0 0 20px",
    padding: "0 20px 0 0"
  },
  tableCell: {
    "&:hover": {
      backgroundColor: "rgba(1,1,1,0.0625)",
      cursor: "pointer",
    }
  },
  downloadButton: {
    "$tableCell:hover &": {
      opacity: "1.0",
    },
    opacity: "0.25",
    marginRight: "10px",
    marginTop: "5px",
  },
  firstChip: {
    margin: '0.5vh',
  },
  answerButton: {
    margin: "5px"
  },
  statusBarPending: {
    backgroundColor: "#FCBB6D",
    color: 'white'
  },
  statusBarAccepted: {
    backgroundColor: "#4AB19D",
    color: 'white'
  },
  statusBarDeclined: {
    backgroundColor: "#EF3D59",
    color: 'white'
  }
});

const Gravatar = ({ email, alt }) => (
  <Avatar
    alt={alt}
    src={`https://www.gravatar.com/avatar/${MD5(email).toString()}?d=identicon`}
  />
);

class NewComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorSending: false,
      errorMessage: '',
      sendingMessage: false,
      message: '',
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    if(!this.state.message || (this.state.message && this.state.message.length < 10)){
      this.setState({errorMessage: 'El mensaje debe tener como mínimo 10 carácteres', errorSending: true});
    } else{
      this.setState({sendingMessage: true, errorSending: false});
      this.props.sendNewCRMCaseMessage(
        this.props.match.params.id, 
        { 'message': this.state.message,
          'attachments': this.state.attachments || []
        }
      )
      .then(
        response => {
          if(response[0] !== 200) {
            this.setState({
              errorSending: true,
              errorMessage: 'No se pudo enviar el mensaje, vuélvelo a intentar.',
              sendingMessage: false,
            });
          }
        }
      );
    }
  }

  handleUpload = (file) => {
    this.setState({attachments: file});
  }

  handleMessage = event => {
    this.setState({message: event.target.value});
  }

  render(){
    const { t } = this.props;

    return( 
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <Paper style={{ padding: "10px 10px", margin: "20px 0" }}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Gravatar email={this.props.email} />
              </Grid>
              <Grid item xs>
                <h4 style={{ margin: 0, textAlign: "left" }}>{this.props.name}</h4>
                <TextField
                  id="filled-full-width"
                  label="Respuesta"
                  fullWidth
                  margin="normal"
                  onChange={this.handleMessage}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="filled"
                  rows={2}
                  rowsMax={Infinity}
                  multiline
                  disabled={this.state.sendingMessage}
                  value={this.state.message}
                />
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <MultiUploadButton
                      min={1}
                      max={3}
                      label={t('crm_attachment_add')}
                      onChange={this.handleUpload}
                      value={this.state.attachment}
                      anotherLabel={t('crm_attachment')}
                      removeLabel={t('crm_attachment_remove')}
                    />
                  </Grid>
                  {
                    this.state.errorSending &&
                    <Grid>
                      <div style={{color: "red", textDecoration: "underline red"}}>{this.state.errorMessage}</div>
                    </Grid>
                  }
                  <Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.sendingMessage}
                      endIcon={this.state.sendingMessage ? <CircularProgress size={25}/> : <SendIcon />}
                      type="submit"
                      style={{marginLeft: 5, marginTop: 5}}
                    >
                      {this.state.sendingMessage ? t("common:enviado") : t("common:send") }
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
    );
  }
}

const mapDispatchToProps = {
  sendNewCRMCaseMessage,
  fetchCRMCaseDetail
};

const mapStateToProps = state => {
  return {
    isSignedIn: state.user.isSignedIn,
    userName: state.user.name,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(['common', 'validation']),
  withRouter
)(NewComment);