import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import {fetchContract} from "../../actions";
import Container from "@material-ui/core/Container";
import {ConsumptionDetail, ContractDetail, OwnerDetails} from "./components/";
import _ from "lodash";
import Fade from "@material-ui/core/Fade";
import {LoadingSkeletons} from "../../components";

const styles = theme => ({});

class ContractGeneral extends React.Component {
  componentDidMount() {
    if (
        !this.props.contract || 
        this.props.contract.meter === undefined ||
        (this.props.contract && this.props.contract.name !== this.props.match.params.name)
      ){
      this.props.fetchContract(this.props.match.params.name);
    }
  }

  renderContract() {
    if (this.props.contract && this.props.contract.meter !== undefined) {
      const { contract } = this.props;
      return (
        <React.Fragment>
          <ContractDetail contract={contract}/>
          <OwnerDetails contract={contract}/>
          <ConsumptionDetail contract={contract}/>
        </React.Fragment>
      )
    } else {
      return <LoadingSkeletons fullHeight/>;
    }
  }

  render() {
    return (
      <Fade in={true}>
        <Container maxWidth={"lg"} >
          {this.renderContract()}
        </Container>
      </Fade>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contract: _.get(state, `contracts.items.${ownProps.match.params.name}`, null),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    fetchContract
  })(ContractGeneral)
);
