import ResponsiveContext from './ResponsiveContext';
import React from 'react';
import { useMediaQuery } from "react-responsive";

const ResponsiveContextProvider = props => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const lowScreen = useMediaQuery({ maxHeight: 850 });

  const device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
  return (
    <ResponsiveContext.Provider value={{ isPortrait, device, lowScreen }}>
      {props.children}
    </ResponsiveContext.Provider>
  )
};

export default ResponsiveContextProvider;