import React from 'react';
import * as PropTypes from "prop-types";
import {AreaChart, BarChart} from "./";

class ChartWrapper extends React.Component {
	constructor(props) {
    super(props);

    const { startDate, endDate } = props;
    this.state = {
      startDate, endDate
    }
  }

  // We only update the chart if dates change. That's why this component is made
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextState.startDate !== this.state.startDate || nextState.endDate !== this.state.endDate;
  }

  render() {
    const { chartType, ...rest } = this.props;

    if (chartType === 'area') {
      return <AreaChart {...rest}/>;
    }
    else if (chartType === 'bar') {
      return <BarChart {...rest}/>;
    }
  }
}

ChartWrapper.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aspect: PropTypes.number,
  chartData: PropTypes.array,
  chartType: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default ChartWrapper;