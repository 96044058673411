import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import { AppBar, Toolbar, withStyles } from '@material-ui/core';
import { LinkRouter } from "../../../../components";
import Breadcrumb from "./Breadcrumb";
import UserSection from "./UserSection";
import { BarLogo } from "../../../../config";
import { fetchCRMCaseSections } from "../../../../actions";

const styles = theme => ({
  mainAppbar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
  },
  breadcrumbAppbar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark
  },
  flexGrow: {
    flexGrow: 1
  },
  title: {
    color: theme.palette.primary.contrastText,
    marginLeft: '5vw'
  },
});

class Topbar extends React.Component {
  componentDidMount(){
    if(this.props && !this.props.sections){
      this.props.fetchCRMCaseSections('RECLAMACIO');
      this.props.fetchCRMCaseSections('EVEHICLE');
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <AppBar
          className={classes.mainAppbar}
          position="relative"
        >
          <Toolbar>
            <LinkRouter to="/">
              <BarLogo />
            </LinkRouter>
            <div className={classes.flexGrow}/>
            <UserSection 
              reclamacions={_.get(this.props, 'sections.RECLAMACIO.data', false) && this.props.sections.RECLAMACIO.data.length > 0 ? true : false}
              evehicle={_.get(this.props, 'sections.EVEHICLE.data', false) && this.props.sections.EVEHICLE.data.length > 0 ? true : false}
              />
          </Toolbar>
        </AppBar>
        <AppBar
          className={classes.breadcrumbAppbar}
          position="relative"
        >
          <Toolbar variant="dense">
            <Breadcrumb/>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchCRMCaseSections
};

const mapStateToProps = (state) => {
  return {
    sections: _.get(state, 'CRMCases.sections', {}),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Topbar);
