import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { openGlobalSnackbar, fetchContracts } from '../../actions';
import ContractListItem from "./components/ContractListItem";
import Fade from "@material-ui/core/Fade";
import { InfoContainer, LoadingSkeletons } from "../../components";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Settings from "../../settings.yml";


const styles = theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  },
  pag: {
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2)
  },

});


class ContractList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      offset: 0,
      limit: parseInt(Settings.contractsLimitPerGet),
    }
  }

  componentDidMount() {
    if(_.get(this.props, "contracts.length", 0) < this.state.limit){
      this.setState(
        {isFetching: true}, 
        () => {
          this.props.fetchContracts(this.state.offset, this.state.limit)
          .then(
            ([statusCode,]) => {
              this.setState(() => ({ isFetching: false }));
              if (statusCode === 401) {
                this.props.openGlobalSnackbar(this.props.t('validation:error_fetching_data'), true);
              }
            }
          );
        }
      );
    }
  }
  
  getNextListItems(){
    this.setState({isFetching: true},
      () => {
        this.props.fetchContracts(this.props.offset + this.state.limit, this.state.limit)
        .then(
          ([statusCode,]) => {
            this.setState(() => ({ isFetching: false }));
            if (statusCode === 401) {
              this.props.openGlobalSnackbar(this.props.t('validation:error_fetching_data'), true);
            }
          }
        );
      }
    );
  }

  getPreviousListItems(){
    this.setState({isFetching: true});
      this.props.fetchContracts(this.props.offset - this.state.limit, this.state.limit)
      .then(
        ([statusCode,]) => {
          this.setState(() => ({ isFetching: false }));
          if (statusCode === 401) {
            this.props.openGlobalSnackbar(this.props.t('validation:error_fetching_data'), true);
          }
        }
      );
  }

  renderListItems () {
    return this.props.contracts.map(
      (el, _) => (
       <ContractListItem
          key={el.id}
          contract={el}
        />
      )
    )
  }

  renderTable() {
    if (!this.state.isFetching) {
      const classes = this.props.classes;
      const { t } = this.props;
      return (
        <Table>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.header}>{t("code")}</TableCell>
              <TableCell className={classes.header}>{t("cups")}</TableCell>
              <TableCell className={classes.header}>{t("address")}</TableCell>
              <TableCell className={classes.header}>{t("tariff")}</TableCell>
              <TableCell className={classes.header}>{t("contracted_power")}</TableCell>
              <TableCell className={classes.header}>{t("tg")}</TableCell>
              <TableCell className={classes.header}>{t("state")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderListItems()}
          </TableBody>
        </Table>
      )
    }
    else {
      return <LoadingSkeletons fullHeight/>;
    }
  }

  renderPagination() {
    const { classes, t, n_items } = this.props;
    let offset_limit = (this.props.offset + this.state.limit)
    if(!this.state.isFetching){
      return (
        <Container maxWidth="sm" className={classes.pag}>
          {
            this.props.offset - this.state.limit >= 0 && 
            <Button startIcon={<ArrowBackIcon/>} onClick={() => {this.getPreviousListItems()}}>
              {t("contract_list_back")}
            </Button>
          }
          {t("contract_list_showing", 
            {
              offset: this.props.offset, 
              limit: offset_limit < n_items ? offset_limit : n_items, 
              n_items: n_items
            })
          }
          {
            this.props.offset + this.state.limit <= this.props.n_items && 
            <Button endIcon={<ArrowForwardIcon/>} onClick={() => {this.getNextListItems()}}>
              {t("contract_list_next")}
            </Button>
          }
        </Container>
      );
    }
  }
  
 
  render() {
    const { classes, t } = this.props;
    return (
      <Fade in={true}>
      
        <InfoContainer
          maxWidth={"lg"}
          title={t("contract_list_title")}
          subtitle={t("contract_list_subtitle")}
          className={classes.container}
          goBackArrow={'/inicio'}
          >
       
          {this.renderTable()}
          {this.renderPagination()}
          
        </InfoContainer>

       
      </Fade>
      
      
    )
  }
}

const mapStateToProps = state => {
  return {
    contracts: Object.values(_.get(state, 'contracts.items', {})),
    isSignedIn: state.user.isSignedIn,
    userName: state.user.name,
    offset: state.contracts.offset,
    n_items: state.contracts.n_items,
  };
};

const mapDispatchToProps = {
  fetchContracts,
  openGlobalSnackbar,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'validation'])
)(ContractList);
