import React from 'react';
import { theme } from "../../../config";
import {
  Area,
  AreaChart as RechartsAreaChart,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { withStyles } from "@material-ui/styles";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { DateAxisTick } from "./DateAxisTick";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { round } from '../../../utils';


const styles = theme => ({
  cchContainer: {
    userSelect: 'none',
  },
  lineColor: {
    color: theme.palette.secondary.main
  },
  activeDotColor: theme.palette.secondary.dark,
  dotColor: 'white'
});

const CustomizedDot = (props) => {
  const {cx, cy, active, dataKey} = props;
  let radius = 25;
  const baseColor = dataKey === 'active_import' ? theme.palette.primary : theme.palette.secondary;
  let fillColor = baseColor.light;
  if (active) {
    radius = 40;
    fillColor = baseColor.main;
  }

  return (
    <svg x={cx - 10} y={cy - 10} width={20} height={20} fill={fillColor} viewBox="0 0 100 100">
      <circle cx="50" cy="50" r={radius}/>
    </svg>
  );
};

class AreaChart extends React.Component {

  getMaxChartData(chartData) {
    let max = 0;
    chartData.forEach(
      row => {
        // The key attr is the name of the series. For example, for a CCH chart
        // the series may be the active import and active export values.
        // And the key is "active_import" and "active_export".
        this.props.series.forEach(
          series => {
            max = row[series.key] > max ? row[series.key] : max
          }
        )
      }
    );
    return max;
  }

  initialState(chartData) {
    const top = this.getMaxChartData(chartData);
    return {
      chartData: chartData,
      left : 'dataMin',
      right : 'dataMax',
      animation : true,
      bottom : 0,
      top: round(top * 1.1, 1)
    }
  }

	constructor(props) {
    super(props);
    const { chartData } = props;
    this.state = this.initialState(chartData);
  }

  renderChart() {
	  const mainStrokeColor = theme.palette.primary.main;
	  const secStrokeColor = theme.palette.secondary.main;
	  const { chartData, top, bottom } = this.state;
    const {
      classes, customTooltip, series, xAxisTickFormatter,
      yAxisTickFormatter, descriptor, margin
    } = this.props;

	  if (chartData) {
      return (
        <RechartsAreaChart
          data={chartData}
          className={classes.cchContainer}
          margin={margin}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis
            dataKey={descriptor}
            type="category"
            tick={<DateAxisTick tickFormatter={xAxisTickFormatter}/>}
            height={100}
            interval="preserveStartEnd"
          />
          {
            series.map(
              ({label, key}, idx) => {
                const orientation = idx === 0 ? "left" : "right";
                const leftProps = {
                  position: idx === 0 ? "insideTopLeft" : "insideTopRight",
                  dx: -20 * (idx === 0 ? -1 : 1)
                };
                return <YAxis
                  allowDataOverflow={true}
                  type="number"
                  domain={[bottom, top]}
                  key={idx.toString()}
                  yAxisId={idx.toString()}
                  tickCount={15}
                  tickFormatter={yAxisTickFormatter}
                  offset={0}
                  label={{ value: label, dy: -40, ...leftProps }}
                  interval="preserveStartEnd"
                  stroke={idx === 0 ? mainStrokeColor : secStrokeColor}
                  orientation={orientation}
                />
              }
            )
          }
          <Tooltip
            content={customTooltip}
            offset={20}
          />
          <Brush
            dataKey='id'
            height={30}
            stroke={theme.palette.secondary.main}
          />
          {
            series.map(
              ({label, key}, idx) => <Area
                yAxisId={idx.toString()}
                type="monotone"
                key={idx.toString()}
                dataKey={key}
                dot={<CustomizedDot/>}
                activeDot={<CustomizedDot active/>}
                fillOpacity={0.2}
                strokeWidth={2}
                fill={idx === 0 ? mainStrokeColor : secStrokeColor}
                stroke={idx === 0 ? mainStrokeColor : secStrokeColor}
              />
            )
          }
        </RechartsAreaChart>
      )
    }
    else {
      return <React.Fragment/>;
    }
  }

  render() {
    const { width, aspect, t } = this.props;

    return (
      <React.Fragment>
        <ResponsiveContainer aspect={aspect} width={width}>
          {
            this.renderChart()
          }
        </ResponsiveContainer>
        <div>
          <Typography variant="subtitle1" style={{textAlign: 'center', color: theme.palette.grey[500]}}>
            {t('select_date_range_with_bar')}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

AreaChart.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aspect: PropTypes.number,
  chartData: PropTypes.array,
  series: PropTypes.array,
  descriptor: PropTypes.string,
  xAxisTickFormatter: PropTypes.func,
  yAxisTickFormatter: PropTypes.func,
  referenceLine: PropTypes.number
};

export default compose(
  withTranslation(['common', 'validation']),
  withStyles(styles)
)(AreaChart);