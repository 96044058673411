import { Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { i18n, theme } from "../../../config";
import React from "react";
import 'moment/locale/es';
import { returnDescriptiveDate } from "../../../utils";
import NumberFormat from "react-number-format";

export const MaximeterTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <Card style={{minWidth: 150}} variant="outlined">
        <CardContent>
          <Typography variant="body2">{`${returnDescriptiveDate(payload[0].payload.month)}`}</Typography>
          <Typography
            color="textPrimary"
            variant="h6" component="h6" style={{
              color: theme.palette.primary.dark,
              marginTop: 10
            }}>
            {`${i18n.t('common:max_power')}: `}
            <NumberFormat displayType="text" value={payload[0].payload.max} suffix={" kW"} decimalSeparator={","} thousandSeparator={"."}/>
          </Typography>
          <Typography
            color="textPrimary"
            component="h5"
            variant="h6" style={{
              marginTop: 10
            }}>
            {`${returnDescriptiveDate(payload[0].payload.date_max)}`}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return null;
};