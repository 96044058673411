import {
  FETCHING_CRM_CASE_LIST,
  FETCHING_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_LIST,
  FETCH_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_SECTIONS,
  FETCH_CRM_CASE_CATEGORIES,
  FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  ADD_CRM_CASE_MESSAGE,
  OPEN_CRM_CASE_MESSAGE,
  RESET_STORE,
  SEND_NEW_CRM_CASE,
  RESPONSE_NEW_CRM_CASE,
} from "../actions/types";

import { INITIAL_CRM_STATE } from "./index";

import _ from 'lodash';


export default (state = INITIAL_CRM_STATE, action) => {
  switch (action.type) {
    case FETCHING_CRM_CASE_LIST:
      return {
        ...state,
        loadingCRMCases: action.payload.loadingCRMCases
      };
    case FETCH_CRM_CASE_LIST:
      return {
        ...state,
        message: action.payload.message,
        fetchedSectionCases: action.payload.fetchedSectionCases,
        loadingCRMCases: action.payload.loadingCRMCases,
        items: action.payload.data
      };
    case FETCHING_CRM_CASE_DETAIL:
      return {
        ...state,
        loadingCRMCase: action.payload.loadingCRMCase
      };
    case FETCH_CRM_CASE_DETAIL:
      return {
        ...state,
        message: action.payload.message,
        case: action.payload.data,
        loadingCRMCase: action.payload.loadingCRMCase,
      };
    case FETCH_CRM_CASE_SECTIONS:
      const fetchedSectionName = action.payload.fetchedSection;
      return {
        ...state,
        sections: { 
          ...state.sections,
          [fetchedSectionName]: action.payload.data
        },
        fetchedSection: fetchedSectionName,
        categories: []
      };
    case FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST:
      return {
        ...state,
        sections_attachments: action.payload.data.data,
      };
    case CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST:
      return {
        ...state,
        sections_attachments: [],
      };
    case FETCH_CRM_CASE_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data
      };
    case ADD_CRM_CASE_MESSAGE:
      const nMails = 
        _.castArray(state.case.mails)
        .concat(action.payload.data);
      return {
        ...state,
        case: {
          ...state.case,
          mails: nMails,
          answerOpen: false,
          attachments: action.payload.data.attachments
        }
      }
    case OPEN_CRM_CASE_MESSAGE:
      return {
        ...state,
        case: {
          ...state.case,
          answerOpen: true
        }
      }
    case SEND_NEW_CRM_CASE:
      return {
        ...state,
        sendingCRMCase: action.payload.sendingCRMCase
      };
    case RESPONSE_NEW_CRM_CASE:
      return {
        ...state,
        sendingCRMCase: action.payload.sendingCRMCase
      };
    case RESET_STORE:
      return INITIAL_CRM_STATE;
    default:
      return state;
  }
};