import {
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
} from '../actions/types';
import { INITIAL_GLOBAL_STATE } from "./index";

export default ( state = INITIAL_GLOBAL_STATE, action ) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpen: true,
          message: action.payload.message,
          error: action.payload.error,
        }
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpen: false,
        }
      };
    default:
      return state;
  }
}

