import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  text: {
    margin: theme.spacing(1)
  },
  subtitle: {
    ...theme.typography.h6,
    fontWeight: 300,
  }
}));

const InternalError = () => {

  const classes = useStyles();
  const {t} = useTranslation(['common', 'validation']);

  return (
    <Fade in={true}>
      <Container className={classes.root} maxWidth="md">
        <Card className={classes.content} variant="outlined">
          <Typography variant="h4" className={classes.text}>
            {t('validation:internal_error')}
          </Typography>
          <Typography variant="h6" className={clsx(classes.text, classes.subtitle)}>
            {t('validation:internal_error_explanation')}
          </Typography>
        </Card>
      </Container>
    </Fade>
  );
};

export default InternalError;
