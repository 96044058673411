import React from 'react';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import {
  getICPStateName,
  renderICPStatusIndicator,
  returnDescriptiveDate,
  round,
  safeGet
} from "../../../utils";
import _ from "lodash";
import { Grid, withStyles } from "@material-ui/core";
import { InfoContainer, LoadingSkeletons } from "../../../components";
import { ContractService } from "../../../service";
import { Refresh, Schedule } from "@material-ui/icons";
import * as PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import MeasuresTableHeader from "./MeasuresTableHeader";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { ResponsiveContextConsumer } from '../../../contexts';
import Fade from "@material-ui/core/Fade";

const styles = theme => ({
  measuresTable: {
    marginBottom: theme.spacing(2),
  },
  mainButton: {
    backgroundColor: theme.palette.primary.light,
    width: "100%",
    marginTop: theme.spacing(2)
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    minHeight: "70vh"
  },
  errorMessage: {
    fontStyle: 'italic'
  }
});

class MeasuresHistory extends React.Component {
  constructor(props) {
    super(props);

    this.contractService = new ContractService();
    this.state = {
      historyRefresh: false,
      instantMeasures: [],
      fetchingMeasures: false,
      pageNumber: 0,
      order: 'desc',
      orderByField: 'exec_datetime',
      rowsPerPage: 10
    }
  }

  componentWillUnmount() {
    this.contractService.cancelRequests();
  }

  isDataFetched() {
    return !_.isEmpty(this.state.instantMeasures);
  }

  setPowerTotal() {
    if (this.isDataFetched()) {
      let newMeasures = this.state.instantMeasures;
      for (let measure of newMeasures) {
        if (measure.current_switch_state === 1) {
          measure.totalPimp = (measure.pimp1 + measure.pimp2 + measure.pimp3)/ 1000;
          measure.totalPexp = (measure.pexp1 + measure.pexp2 + measure.pexp3)/ 1000;
        }
      }
      this.setState({
        instantMeasures: newMeasures
      });
    }
  }

  fetchMeterMeasures() {
    this.setState({fetchingMeasures: true});
    this.contractService.fetchInstantMeterMeasures(this.props.contract.name, this.props.contract.meter.serial)
    .then(
      instantMeasures => {
        this.setState({
          instantMeasures: instantMeasures
        }, () => {
          this.setPowerTotal();
        });
        this.setState({fetchingMeasures: false});
      }
    )
  }

  componentDidUpdate(prevProps) {
    const {refresh} = this.props;
    if ((prevProps.refresh !== refresh) && refresh) {
      this.fetchMeterMeasures();
    }
  }

  refreshHistory = () => {
    this.fetchMeterMeasures();
  };

  renderRefreshButton() {
    const {classes, t} = this.props;
    return (
      <Grid item xs={2} className={classes.flexCenter}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          className={classes.mainButton}
          component="button"
          onClick={this.refreshHistory}
        >
          <Refresh className={classes.icon}/>
          <ResponsiveContextConsumer>
            {
              values => values.device !== 'mobile' && t('update')
            }
          </ResponsiveContextConsumer>
        </Fab>
      </Grid>
    )
  }

  componentDidMount() {
    this.fetchMeterMeasures();
  }

  renderMeasuresContent() {
    const {instantMeasures, orderByField, order, pageNumber, rowsPerPage} = this.state;
    const {classes, t} = this.props;
    return (
      _.orderBy(instantMeasures, [orderByField], [order])
      .slice(pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)
      .map(
        (row, idx) => (
          <TableRow key={idx} hover>
            <TableCell align="center">
              <div className={classes.flexCenter}>
                <span height="15">
                  {
                    row.origin === "scheduled" &&
                    <Schedule style={{marginRight: '10px'}}/>
                  }
                </span>
                <span height="15">
                  {
                    returnDescriptiveDate(row.exec_datetime, 'YYYY-MM-DD HH:mm:ss')
                  }
                </span>
              </div>
            </TableCell>
            {_.get(row, 'executed') === 2 &&
              <TableCell align="center" colSpan="5">
                <span className={classes.errorMessage}>{t('common:error_communication')}</span>
              </TableCell>
            }
            {(_.get(row, 'executed') === 0 || _.get(row, 'executed') === 1) &&
              <React.Fragment>
                <TableCell align="center">{
                  _.get(row, 'current_switch_state') ?
                  safeGet(row, 'ai', '⸺', value => value.toString().replace(",", ".")) : '⸺'
                }</TableCell>
                <TableCell align="center">{
                  _.get(row, 'current_switch_state') ?
                  safeGet(row, 'ae', '⸺', value => value.toString().replace(",", ".")) : '⸺'
                }</TableCell>
                <TableCell align="center">{
                  safeGet(row, 'totalPimp', '⸺', value => value.toString().replace(".", ","))
                }</TableCell>
                <TableCell align="center">{
                  safeGet(row, 'totalPexp', '⸺', value => value.toString().replace(".", ","))
                }</TableCell>
                <TableCell align="center">
                  <div className={classes.flexCenter}>
                    {renderICPStatusIndicator(_.get(row, 'current_switch_state'), this.isFuture(row))}
                    {
                      safeGet(row, 'current_switch_state', 
                        this.isFuture(row) ? t('common:programmed') : "⸺",
                        currentState => getICPStateName(
                          safeGet(row, 'previous_switch_state'),
                          currentState
                        )
                      )
                    }
                  </div>
                </TableCell>
              </React.Fragment>
            }
          </TableRow>
        )
      )
    )
  }

  isFuture(instantData){
    return _.get(instantData, 'executed') === 0 && _.get(instantData, 'origin') === 'scheduled';
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderByField === property && this.state.order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderByField: property
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      pageNumber: 0
    })
  };

  handleChangePage = (event, newPage) => {
    this.setState({pageNumber: newPage});
  };

  renderMeasuresTable() {
    const {classes,t} = this.props;
    if (!this.state.fetchingMeasures) {
      const {instantMeasures, orderByField, order, pageNumber, rowsPerPage} = this.state;
      return (
        <Fade in={true}>
          <TableContainer>
            <Table
              className={classes.measuresTable}
              size="medium"
              aria-label="measures table"
            >
              <MeasuresTableHeader
                order={order}
                orderBy={orderByField}
                onRequestSort={this.handleRequestSort}
                rowCount={instantMeasures.length}
              />
              <TableBody>
                {this.renderMeasuresContent()}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t('common:number_of_rows')}
              count={instantMeasures.length}
              page={pageNumber}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </TableContainer>
        </Fade>
      )
    } else {
      return <LoadingSkeletons fullHeight style={{marginTop: "10%"}}/>;
    }
  }

  render() {
    const {classes, t} = this.props;
    return (
      <InfoContainer
        title={t('common:measure_history')}
        subtitle={t('common:measure_history_subtitle')}
        titleAvatar={this.renderRefreshButton()}
        className={classes.container}
      >
        <Divider/>
        {
          this.renderMeasuresTable()
        }
      </InfoContainer>
    )

  }
}

MeasuresHistory.propTypes = {
  contract: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withTranslation(['common', 'validate'])
)(MeasuresHistory)
