import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import Container from "@material-ui/core/Container";
import _ from 'lodash';

export const LoadingSkeletons = props => {
  let additionalStyles = _.get(props, 'style', {});
  if (props.fullHeight) {
    additionalStyles.height = '100%';
    additionalStyles.display = 'flex';
    additionalStyles.justifyContent = 'center';
    additionalStyles.alignItems = 'center';
  }
  return (
    <Container
      style={{
        ...additionalStyles
      }}
    >
      <div style={{width: '40vw'}}>
        <Skeleton animation="wave"/>
        <Skeleton animation="wave"/>
        <Skeleton animation="wave"/>
        <Skeleton animation="wave"/>
      </div>
    </Container>
  )
};