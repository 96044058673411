import apiWrapper from "./AxiosRequestsClass";

export default class CRMService {
  cancelRequests = () => {
    return apiWrapper.cancelRequests();
  };

  constructor(config) {
    this.config = config;
  }

  getCRMCasesList = (section) => {
    let url = '/crm/cases/';
    if (section) {
      url += `?filter=('section_code','=','${section}')`;
    }
    return apiWrapper.get(url, this.config, '/v2');
  };

  getCRMCaseDetail = id => {
    return apiWrapper.get(`/crm/cases/${id}/`, this.config, '/v2');
  };

  getCRMCaseSections = (section) => {
    let url = '/crm/cases/sections/';
    if (section) {
      url += `?filter=('code','=','${section}')`;
    }
    return apiWrapper.get(url, this.config, '/v2');
  }

  getCRMCaseSectionAttachmentsList = id => {
    return apiWrapper.get(`/crm/cases/sections/${id}/attachments/`, this.config, '/v2');
  }

  getCRMCaseDownloadSectionAttachment = id => {
    return apiWrapper.get(`/crm/cases/sections/attachments/${id}/`, this.config, '/v2');
  }

  getCRMCaseCategories = id => {
    return apiWrapper.get(`/crm/cases/sections/${id}/categories/`, this.config, '/v2');
  }

  getCRMCaseDownloadAttachment = (caseId, attachmentId) => {
    return apiWrapper.get(`/crm/cases/${caseId}/attachments/${attachmentId}/`, this.config, '/v2');
  }

  postCRMCaseMessage = (id, data) => {
    return apiWrapper.post(
      `/crm/cases/${id}/`,
      data,
      this.config,
      '/v2'
    )
  }

  postCRMCase = (data) => {
    return apiWrapper.post(
      '/crm/cases/',
      data,
      this.config,
      '/v2'
    )
  }
}