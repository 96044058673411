import {
  SNACKBAR_CLOSE,
  SNACKBAR_OPEN,
} from "./types";

export const closeGlobalSnackbar = () => {
  return {
    type: SNACKBAR_CLOSE
  }
};

export const openGlobalSnackbar = (message, isError=false) => {
  return {
    type: SNACKBAR_OPEN,
    payload: {
      message,
      error: isError
    }
  }
}
