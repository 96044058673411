import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as PropTypes from "prop-types";
import _ from 'lodash';
import { history } from '../../../config';
import NumberFormat from "react-number-format";

const ContractListItem = props => {
  const { contract } = props;
  const fieldsToRender = [
    "name", "cups.name", "cups.full_address", "tariff.name", "contracted_power", "tg", "state"
  ];

  const renderField = field => {
    const value = _.get(contract, field);
    switch(field) {
      case "contracted_power":
        return <NumberFormat displayType="text" value={value} suffix={" kW"}
                              decimalSeparator={","} thousandSeparator={"."}/>;
      default:
        return value;
    }
  }

  return (
    <TableRow hover>
      {
        fieldsToRender.map(
          (field, idx) => (
            <TableCell
              key={idx}
              style={{
                textAlign: 'center',
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/contratos/${contract.name}`)}
            >

              {
                renderField(field)
              }
            </TableCell>
          )
        )
      }
    </TableRow>
  );
};

ContractListItem.propTypes = {
  contract: PropTypes.object,
};

export default ContractListItem;
