import React from 'react';
import { history } from '../config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});
    console.error(error);
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV !== 'development') {
      history.push("/internal-error");
      return null;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;