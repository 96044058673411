import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import _ from 'lodash';
import { getLang, resources } from "../../i18n";
import Settings from "../../settings.yml";

const SUPPORTED_LANGS = ['es', 'ca'];
const lang = getLang();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: process.env.NODE_ENV === 'development',
    fallbackLang: Settings.defaultLanguage,
    lng: _.indexOf(SUPPORTED_LANGS, lang) > -1 ? lang : Settings.defaultLanguage,
    ns: [
      'common', 'validation'
    ],
    defaultNs: 'common',
  });


export default i18n;