import React from "react";
import { default as i18n } from "../config/i18n";
import { isDefined, safeGet } from "./index";
import 'status-indicator/styles.css';
import Settings from "../settings.yml";
import _ from "lodash";

/*
* Render a status-indicator given a ICP state
* */
export function renderICPStatusIndicator(icpState, isFuture) {
  return (
    <status-indicator
      positive={
        isDefined(icpState) && isConnectedICP(icpState) && !isFuture ?
          true : undefined
      }
      pulse={
        isDefined(icpState) && isConnectedICP(icpState) && !isFuture ?
          true : undefined
      }
      negative={
        isDefined(icpState) && !isConnectedICP(icpState) && !isFuture ?
          true : undefined
      }
      intermediary={
        isDefined(icpState) && !isConnectedICP(icpState) && isFuture ?
        true : undefined
      }
      style={{margin: 10}}
    />
  );
}

/*
* Return true if the ICP switch state is CLOSED
* */
export function isConnectedICP(icpState) {
  return icpState === 1;
}

/*
* Return true if the ICP switch state is "OPEN - ready for reconnection"
* */
export function canReconnectICP(icpState) {
  return icpState === 2;
}

/*
* Return the name of the ICP switch state
* */
export function getICPStateName(previousState, currentState) {
  let result;

  if ((previousState === 2 && currentState === 0) || (previousState === 1 && currentState === 2))
    result = i18n.t("common:disconnected");

  else if (previousState === 1 && currentState === 0)
    result = i18n.t("common:cutoff");

  else if (previousState === 0 && currentState === 1)
    result = i18n.t("common:connected");

  else
    // The default value will be "connected" even though it shouln't because
    // this else-block should never be reached by STG specifications
    result = i18n.t("common:connected");

  return result
}

/*
* Return true the meter detail view should be enabled
* */
export function meterDetailEnabled(meter) {
  return safeGet(meter, "tg", false) && _.get(Settings, 'meterDetailEnabled', false);
}