import React  from 'react';
import {withStyles} from '@material-ui/core';
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { organization } from '../../../../config';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    flexShrink: 0
  },
  footerTitle: {
    textAlign: 'center',
    color: theme.palette.grey[500]
  }
});

class Footer extends React.Component {

  render() {
    const {className, classes, ...rest} = this.props;

    return (
      <Box
        {...rest}
        className={clsx(className, classes.root)}
        borderTop={1}
        borderColor="grey.200"
      >
        <Typography variant="h6" className={classes.footerTitle}>
          {organization.name}
        </Typography>
      </Box>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(Footer);
