import _ from "lodash";

export const parseResponse = (response) => {
  // The response from AXIOS is different if it's a Promise.reject or
  // Promise.resolve like
  const statusCode = _.get(response, 'status', _.get(response, 'response.status', 500));
  const data = _.get(response, 'data', _.get(response, 'response.data', {}));
  return [statusCode, data];
}

/*
* Delayed Promise creation
* */
export const pause = (duration) => new Promise(res => setTimeout(res, duration));

/*
* Method to handle retries over a called function until it resolves the Promise.
* In our cas we'll retry the call if the HTTP response is 202 (Accepted)
* */
export const insist = (fn, args=[], retries, delay = 500) => {
  return fn(...args)
    .catch(
      (error) => {
        const [statusCode,] = parseResponse(error)
        return statusCode === 202 && retries > 1 ?
          pause(delay).then(() => insist(fn, args, retries - 1, delay))
         :
          Promise.reject(error)
      }
    );
}