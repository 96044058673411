import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  topbar: {
    height: "7vh"
  },
  container: {
    height: "86vh"
  },
  footer: {
    height: '7vh'
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar className={classes.topbar}/>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.container}
      >
        {children}
      </Grid>
      {/*<Footer className={classes.footer}/>*/}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
