import React from "react";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { HomeOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { theme } from '../../../../config';
import { LinkRouter } from "../../../../components";
import CRMDetailBreadcrumb from "./CRMDetailBreadcrumb";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";

export const viewsNames = i18n => {
  return [
    {
      path: "/",
      breadcrumb: null,
    },
    {
      path: "/reset",
      breadcrumb: i18n.t('common:reset_password_text'),
    },
    {
      path: "/contratos",
      breadcrumb: i18n.t('common:your', {count: 2, what: i18n.t('common:contract', {count: 2})}),
    },
    {
      path: "/contratos/:name",
      breadcrumb: ({ match }) => <span>{i18n.t("common:contract", { contract: match.params})}</span>,
    },
    {
      path: "/contratos/:name/contador",
      breadcrumb: i18n.t('common:meter'),
    },
    {
      path: "/solicitudes",
      breadcrumb: null,
    },
    {
      path: "/solicitudes/:type",
      breadcrumb: ({ match }) => <span>{i18n.t(`common:crm_distri_${match.params.type}`)}</span>,
    },
    {
      path: "/solicitudes/:type/crear",
      breadcrumb: i18n.t('common:crm_new_case'),
    },
    {
      path: "/solicitudes/:type/:id",
      breadcrumb: CRMDetailBreadcrumb,
    },
  ];
}

const Breadcrumbs = ({ breadcrumbs }) => {
  const RenderBcText = (props) => (
    <Typography variant="subtitle1" style={{
        margin: 0,
        color: theme.palette.primary.contrastText,
      }}>{props.children}</Typography>
  );

  const RenderBcSeparator = (props) => (
    <span style={{margin: 10}}>{"/"}</span>
  );

  return (
    <Container maxWidth={"lg"} style={{display: 'flex', alignItems: 'center'}}>
      <LinkRouter to={"/"} style={{
        textDecoration: 'none',
        margin: 0,
        color: theme.palette.primary.contrastText,
      }}>
        <HomeOutlined/>
      </LinkRouter>
      <RenderBcSeparator/>
      {
        breadcrumbs.map(
          ({breadcrumb, path, match}, index) => (
            <span key={index}>
              {index < breadcrumbs.length - 1 ? (
                <LinkRouter to={match.url} style={{
                  textDecoration: 'none',
                  color: theme.palette.primary.contrastText,
                }}>
                  <RenderBcText>
                    {breadcrumb}
                    {(index < breadcrumbs.length - 1) && (
                      <RenderBcSeparator/>
                    )}
                  </RenderBcText>
                </LinkRouter>
              ) : (
                <RenderBcText>
                  {breadcrumb}
                </RenderBcText>
              )}
            </span>
          )
        )
      }
    </Container>
  )
};

const MappedBreadcrumb = () => {
  // We make this component to get the i18n updated when the language changes.
  const { i18n } = useTranslation();
  const MappedBreadcrumb = withBreadcrumbs(viewsNames(i18n), { excludePaths: ['/'] })(Breadcrumbs);
  return <MappedBreadcrumb/>;
}

export default MappedBreadcrumb;