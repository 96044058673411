import moment from "moment";
import 'moment/locale/es';
import _ from "lodash";
import React from "react";

export * from './meter';

/*
* Rounds the float number to X **decimals** positions
* */
export const round = (num, decimals) => {
  return parseFloat(num.toFixed(decimals));
};

/*
* True if **date** matches **format**
* */
function dateMatchingFormat(date, format) {
  return moment(date, format).format(format) === date;
}

/*
* Returns the date **date** in a formatted value, more descriptive and
* human-readable.
*
* The format can be indicated via argument **format**, or will be guessed by an
* algorithm of known formats
* */
export const returnDescriptiveDate = (date, format = false) => {
  let formattedDate = date;
  const possibleFormats = {
    'YYYY-MM-DD HH:mm:ss': 'D [de] MMMM [de] Y, [a las] HH:mm',
    'YYYY-MM-DD HH': 'D MMM Y HH[h]',
    'YYYY-MM-DD': 'D MMM Y',
    'YYYY-MM': 'MMM Y'
  };
  for (const [possibleFormat, formatToConvert] of Object.entries(possibleFormats)) {
    // If a format is passed via args. and matches, the date will be formatted.
    // Otherwise, we'll try a possible format
    if ((format && possibleFormat === format) || dateMatchingFormat(date, possibleFormat)) {
      formattedDate = moment(date, possibleFormat).format(formatToConvert);
      break;
    }
  }
  return formattedDate;
};

/*
* True if **x** is "defined", which we'll consider a value DEFINED and NOT-NULL
* */
export const isDefined = (x) => typeof x !== "undefined" && x !== null;

/*
* Get the **field** from **obj**;
*   - If exists, it will be formatted with the **formatter**
*   - Else, will return the **otherwise** value.
* */
export const safeGet = (obj, field, otherwise="N/A", formatter=null) => {
  const value = _.get(obj, field, null);
  if (!isDefined(value) || value === "" || value === false) {
    return otherwise;
  }
  else {
    if (isDefined(formatter))
      return formatter(value);
    else
      return value;
  }
};

/*
* Function that's like the Python str.title().
* E.g.: hello it's me! -> Hello It's Me!
* */
export function title (text) {
    return text.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

/*
* Small hack to disable logs on production environments
* */
export function disableLogs(){
  function noop() {}

  if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
}

/*
* Returns a valid i18n key, that cannot have dots, brackets and other symbols.
* */
export function validi18nKey(key) {
  return key.replace('.', '');
}

/**
 * Credit to https://github.com/jitsi/jitsi-meet
 *
 * Translates a specific key to text containing HTML via a specific translate
 * function.
 *
 * @param {Function} t - The translate function.
 * @param {string} key - The key to translate.
 * @param {Object} options - The options, if any, to pass to {@link t}.
 * @returns {ReactElement} A ReactElement which depicts the translated HTML
 * text.
 */
export function translateToHTML(t, key, options = {}) {
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML = {{ __html: t(key, options) }} />;
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}; 