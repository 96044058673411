import React from "react";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import { theme } from '../config';


export const InfoContainerBody = props => {
  const {
   children, subtitle, actionButton, mainContainer
  } = props;
  

  return (
    <Grid
      container justify="space-around" direction="column" alignItems="center"   
    >
        <CardHeader
          subheader={subtitle}
          subheaderTypographyProps={{
            style: {
              ...theme.typography.subtitle1,
              color: mainContainer ? theme.palette.primary.contrastText : theme.typography.h3.color,
            }
          }}
        />
      <Grid>
        {actionButton}  
      </Grid>
      <Grid>
        {children}
      </Grid>
    </Grid>
  );
};

